import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getVideoGroups, deleteVideoGroup } from 'Actions';
import LifeSherpaLoading from '../LifeSherpaLoading';
import PeopleIcon from '@mui/icons-material/People';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import WarningDialog from "../WarningDialog";
import OrganizationsSelection from '../Widgets/OrganizationsSelection';
import { Label } from 'reactstrap';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';

class VideoGroups extends Component {

    state = { deleteWarning: false, group: {} }

    componentDidMount() {
        this.props.getVideoGroups()
    }

    handleSelectVideo=(value)=>{
        if(value.organization) {
            localStorage.setItem('selectedOrgId', value.organization);
        }
        this.props.selectVideo(value)
    }

    handleAddVideoGroup=()=>{
        // const {organizations} = this.props;
        // if(organizations && organizations.length > 1) {
        //     this.setState({showOrganizationsSelection: true})
        // } else {
            this.props.openAddFrom();
        // }
    }

    handleShowDeleteWarning = (group) => {
        this.setState({deleteWarning: true, group: group})
    }

    handleCloseDeleteWarning = () => {
        this.setState({deleteWarning: false})
    }

    handleDeleteSelectedGroup = (groupId) => {
        const {group} = this.state;
        console.log(" group -->",group);
        const orgId = group.organization || localStorage.getItem("orgId");
        this.props.deleteVideoGroup(group.id, orgId);
        this.handleCloseDeleteWarning();
    }
  
    handleCloseOrganizationsSelection = () => {
        this.setState({showOrganizationsSelection: false})
    }
    handelSelectOrg = (orgId) => {
        localStorage.setItem('selectedOrgId', orgId);
        this.props.openAddFrom();
    }

    applySearchFilter = (list) => {
        const {search} = this.props;
        if(search && search.length > 2 && list && list.length > 0) {
            return list.filter((item) => item.name && item.name.toLowerCase().includes(search.toLowerCase()))
        }
        return list;
    }

    render() { 
        let {videoGroupList , loading, organizations, selectedOrgs} = this.props;
        const {group, deleteWarning, showOrganizationsSelection} = this.state;
        let groupsList = [];
        if(videoGroupList && videoGroupList.length) {
            groupsList = selectedOrgs && selectedOrgs.length ? videoGroupList.filter(group => selectedOrgs.includes(group.organization)) : videoGroupList;
            groupsList = this.applySearchFilter(groupsList);
        }
        
        let organizationName = ''
        if (organizations) {
            groupsList = groupsList.map(group => {
                let organizationName = ''
                const index = organizations.findIndex(org => org.id === group.organization);
                if (index !== -1) {
                    organizationName = organizations[index].name;
                }
                group['organizationName'] = organizationName;
                return group;
            })
            
        }
        console.log(" Video groups list -->",groupsList);
        return ( 
            <div>
                {loading ?
                    <LifeSherpaLoading loading={loading}/>
                    :
                    <ul className="list-unstyled m-0 list-sherpa-activity px-0 pt-1 mb-4" data-testid={`video-groups-component`}>
                        {groupsList.length>0 ? groupsList.map((list,index)=>(
                            <li className="list-card  video-groups-list-item contacts-list-item list-item p-2 my-2 " key={index} style={{cursor:'pointer'}}>
                                {list.organizationName && <Label className='mb-0 d-flex px-2 mb-1 align-items-center  form-label'><CorporateFareIcon className='mr-1' />{list.organizationName}</Label>}
                                <div className='d-flex align-items-center'>
                                    <div className="d-flex avatar-wrap w-15 align-self-center " data-testid={`group-icon-${index}`}>
                                        <PeopleIcon className="rounded-circle align-self-center group-icon" />
                                    </div>
                                    <div className="actvity-routine  mx-2" onClick={()=>this.handleSelectVideo(list)}>
                                        <h4 data-testid={`group-name-${index}`}>{list.name}</h4>
                                        <div data-testid={`group-members-${index}`} className="status-text">{list.members.length+" members"}</div>
                                    </div>
                                    {this.props.userRole==='Client' &&
                                        <div className="w-auto ml-auto mr-1 my-auto">
                                            <button className="btn btn-md text-white mx-auto primary-button">Join</button>
                                        </div>
                                    }
                                    <IconButton onClick={()=>this.handleSelectVideo(list)} data-testid={`group-open-icon-button-${index}`}  className=" ml-auto open-group-detials-icon"><ArrowRightIcon color="primary" /></IconButton>
                                    <IconButton className=" delete-icon" onClick= {() => this.handleShowDeleteWarning(list)}> <DeleteIcon/> </IconButton> 
                                </div>
                            </li>
                        )):
                        <div className='col-12 mt-50'>
                            <h2 className="text-secondary mx-auto" style={{marginTop:'100px'}}>No video groups have been created yet.</h2>
                        </div>
                        }
                    </ul>
                }
                {this.props.userRole==='Parent' && 
                    <div className="d-flex align-items-center fixed-bottom mb-3 mr-lg-4" >
                        <IconButton title="Create Group" onClick={() => this.handleAddVideoGroup()} className="add-group-button ml-auto mr-3 mb-2" data-testid="add-new-chat-group"><AddIcon/></IconButton>
                        {/* <button className="addbutton add-group-button rounded-circle text-white ml-auto mt-auto mr-lg-5 mr-2" onClick={()=>this.handleAddVideoGroup()} >+</button> */}
                    </div>
                }
                {/* {showOrganizationsSelection &&
                     <OrganizationsSelection
                        organizations={organizations}
                        handelSelectOrg={this.handelSelectOrg}
                        handleClose={this.handleCloseOrganizationsSelection}
                    />
                } */}
               
                <WarningDialog
                    showWarning = {deleteWarning}
                    warningText = {`Are you sure you want to delete video group: ${group.name}`}
                    cancelText = {"NO"}
                    acceptText = {"YES"}
                    handleCancel = {() => this.handleCloseDeleteWarning()}
                    handleAccept = {() => this.handleDeleteSelectedGroup()}
                />
            </div>
        );
    }
}
const mapStateToProps = ({Contacts,authUser, clientsList}) => {
    const {userRole}=authUser
    const {loading,videoGroupList}=Contacts;
    const { organizations } = clientsList
	return {loading,videoGroupList,userRole, organizations};
};

export default connect(mapStateToProps, {
	getVideoGroups,deleteVideoGroup
})(VideoGroups);