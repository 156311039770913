import React, { Component } from 'react';
import Contacts from './contacts';
import VideoGroups from "./VideoGroups"
import { withStyles } from '@mui/styles';
import UserChat from "./UserChat"
import NewGroup from "./NewGroup"
import { withRouter } from 'react-router-dom';
import {
	setSelectedChatUser,
	removeSelectedUser,
	selectMenuItem,
	hideVideoRoomPanel,
	showVideoRoomPanel,
	getVideoCallerInfoAndOpenConfirmation,
	sendGroupVideoCallResponse,
	networkActivity,
} from "../../actions"
import { connect } from 'react-redux';
import LifeSherpaLoading from '../LifeSherpaLoading';
import queryString from 'query-string';
import config from '../../config/config'
import { database } from '../../firebase';
import { ref, onValue } from "firebase/database"
import { v4 as uuidv4 } from 'uuid';
import { openPopup } from '../../helpers/helpers';
import { IconButton } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import ContactsFilterPopup from '../Widgets/ContactsFilterPopup';
import VideoCallHistory from '../AppCenter/components/VideoCallHistory';
import TextField from '@mui/material/TextField';
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from '@mui/icons-material/Close';

const drawerWidth = 400;
const styles = theme => ({
	root: {
		flexGrow: 1,
		height: 'auto',
		zIndex: 1,
		overflow: 'hidden',
		position: 'relative',
		display: 'flex',
		width: '100%',
	},
	toolbar: theme.mixins.toolbar,
	drawerPaper: {
		width: 340,
		height: '100%',
		[theme.breakpoints.up('md')]: {
			position: 'relative',
			width: drawerWidth,
		},
		backgroundColor: '#fff'
	},
	drawerHeight: {
		height: '100%',
	},
	content: {
		flexGrow: 1
	},
});

class LifesherpaContacts extends Component {
	state = {
		selected: "contacts",
		selectedChat: {},
		selectedVideo: {},
		openChat: false,
		videoToken: '',
		selectedRoom: '',
		roomDetails: null,
		video: '',
		conversationTitle: "",
		inProgressGroups: [],
		fromNotification: false,
		selectedOrgs:[],
		search: ""
	}
	componentDidMount() {
		if (document.getElementById("app-main-conatainer-infinitescroll") && document.getElementById("app-main-conatainer-infinitescroll").firstElementChild) {
			document.getElementById("app-main-conatainer-infinitescroll").firstElementChild.setAttribute("id", "child-conatainer-infinitescroll")
		}
		// this.props.selectMenuItem(2);
		const params = this.getParams();
		if (params) {
			if (params.channelTitle) {
				console.log(" channelTitle ----->", params.channelTitle);
				this.setState({ ...this.state, conversationTitle: params.channelTitle });
				this.props.history.push("/app/contacts");
			} else if (params.roomname && params.callerId) {
				this.props.getVideoCallerInfoAndOpenConfirmation(params.roomname, params.callerId);
				this.props.history.push("/app/contacts");
			}
		}
		this.inProgressGroupList();

	}
	inProgressGroupList = () => {
		let { inProgressGroup } = this.state;
		let orgId = localStorage.getItem('orgId');
		let userId = localStorage.getItem('auth_uid');
		const realtimePath = `Realtime/${orgId}/Users/${userId}/groupCallsInProgress`;
		console.log(" getting realtime room list  --> ", realtimePath);
		onValue(ref(database, realtimePath), (snapshot) => {
			const groups = snapshot.val();
			let inProgressGroups = [];
			console.log(" group list from realtime --> ", groups);
			if (groups && Object.keys(groups).length > 0) {
				Object.keys(groups).map(roomname => {
					let details = groups[roomname];
					details.roomname = roomname;
					let hostName = "";
					let hostUserId = "";
					let currentUser = {};
					if (details.group) {
						details.members && details.members.map(member => {
							if (member.host) {
								hostName = member.name;
								hostUserId = member.userId;
							}
							if (member.userId == userId) {
								if (member.status /*&& member.status !== "accepted"*/) {
									currentUser = member;
								} else if (!member.status) {
									currentUser = member;
								}
							}
						})
					}
					if (currentUser && currentUser.name) {
						details.hostName = hostName;
						details.hostUserId = hostUserId;
						details.currentUser = currentUser;
						details.totalMembers = details.members ? details.members.length : 0;
						inProgressGroups.push(details);
					}
				})
			}
			this.setState({ inProgressGroups: inProgressGroups });
		})
	};
	getParams() {
		let params = null
		if (this.props.location && this.props.location.search) {
			params = queryString.parse(this.props.location.search)
		}
		return params
	}
	handleOpenVideoGroupRoom = (roomname) => {
		if (roomname) {
			console.log(" Joining vidoe call room --> ", roomname);
			this.props.sendGroupVideoCallResponse(roomname, "accepted");
			let openVideoCallRoom = `${config.lsPortalUrl}/lifesherpa/videocall?room=${roomname}`;
			// window.open(openVideoCallRoom,"_blank");
			var windowReference = window.open();
			windowReference.location = openVideoCallRoom;
			openPopup(windowReference)
			console.log(" Joined vidoe call room --> ", roomname);
		}
	}
	removeConversationTitle = () => {
		this.setState({ conversationTitle: "", fromNotification: true });
	}
	selectChat = (e) => {
		this.setState({ openChat: true, selectedChat: e, selected: 'chat' })
		console.log(" selected chat opened --> ", e);
	}
	selectVideo = (groupDetails) => {
		this.setState({ selected: "openGroup", openChat: true, selectedVideo: groupDetails })
		// this.setState({openChat:true,selectedVideo:e,selected:'video'})
	}
	handleBack = () => {
		let { selected } = this.state;
		let orgId = localStorage.getItem("orgId");
		localStorage.setItem('selectedOrgId', orgId);
		if (selected == "newGroup" || selected == "openGroup") {
			selected = 'Video Groups';
		} else {
			selected = selected === 'video' ? 'Video Groups' : 'contacts';
		}
		this.setState({ openChat: false, selectedChat: {}, selectedVideo: {}, selected, fromNotification: false });
		this.props.removeSelectedUser();
		console.log(" back to chat list -->");
		this.props.networkActivity();
	}
	openAddFrom = () => {
		this.setState({ selected: "newGroup", openChat: true, selectedVideo: null })
	}
	selectVideoGroup = (e) => {
		let { selectedVideo } = this.state;
		const { videoToken } = this.props;
		console.log(" selectedVideo -->,", selectedVideo);
		// console.log(" videoToken -->,",videoToken);
		//this.props.showVideoRoomPanel(selectedVideo);
		this.openVidoeRoomNewTab(selectedVideo);
	}
	selectedVideoGroupDetails = (selectedVideo) => {
		console.log(" selectedVideo -->,", selectedVideo);
		this.openVidoeRoomNewTab(selectedVideo);
	}
	handleVideo = (chat) => {
		console.log(" selected Chat -->", chat);
		//this.props.showVideoRoomPanel(chat);
		this.openVidoeRoomNewTab(chat);

	}
	openVidoeRoomNewTab = (selectedChat) => {
		let selectedVideoChat = { ...selectedChat };
		const roomname = uuidv4() // `${Math.random().toString(36).substr(2, 6)}-${Math.random().toString(36).substr(2, 5)}-${Math.random().toString(36).substr(2, 3)}`;
		selectedVideoChat.name = roomname;
		// let members=[];
		let groupId = "";
		
		let videoCallRoomDetails = { name: selectedVideoChat.name }
		if (selectedVideoChat.members) {
			videoCallRoomDetails.members = selectedVideoChat.members;
			videoCallRoomDetails.id = selectedVideoChat.id;
			groupId = selectedVideoChat.id;
		} else if (selectedVideoChat.userId) {
			videoCallRoomDetails.userId = selectedVideoChat.userId;
		}
		videoCallRoomDetails = JSON.stringify(videoCallRoomDetails);
		localStorage.setItem("videoCallRoomDetails", videoCallRoomDetails)
		let openVideoCallRoom = `${config.lsPortalUrl}/lifesherpa/videocall?room=${selectedVideoChat.name}`;
		if (groupId) {
			openVideoCallRoom = `${config.lsPortalUrl}/lifesherpa/videocall?room=${selectedVideoChat.name}&groupId=${groupId}`;
		}
		//let openVideoCallRoom = `${config.lsPortalUrl}/lifesherpa/videocall?room=${selectedVideoChat.name}`;
		//  window.open(openVideoCallRoom,"_blank");
		var windowReference = window.open();
		windowReference.location = openVideoCallRoom;
		openPopup(windowReference)
	}
	handleLogout = () => {
		if (this.state.roomDetails) {
			this.state.roomDetails.localParticipant.tracks.forEach((trackPub) => {
				trackPub.track.stop();
			});
			this.state.roomDetails.disconnect();
		}
		let sel = this.state.video === 'person' ? 'chat' : 'video'
		this.setState({ roomDetails: null, selected: sel, video: '', selectedRoom: '' });
		this.props.hideVideoRoomPanel();
	}

	handelShowFilter = () => {
		this.setState({ ...this.state, showFilter: true });
	}
	clearFilter = () => {
        this.setState({ ...this.state, showFilter: false, selectedOrgs: []});
    }

    applyFilter = (selectedOrgs) => {
        this.setState({ ...this.state, showFilter: false, selectedOrgs});
    }

    handleCloseFilter = () => {
        this.setState({ ...this.state, showFilter: false });
    }
	handelSearch = (e) => {
		// console.log(e);
		 this.setState({...this.state,search:e.target.value});
	 }
	 handelClearSearch = () => {
		 this.setState({...this.state,search:""});
	 }

	render() {
		const { classes, theme, loading, selectedUser, videoToken, selectedVideoChat, showVideoRoom, userRole, contactList } = this.props;
		const { conversationTitle, inProgressGroups, openChat, selected, fromNotification, search } = this.state;
		let idToken = localStorage.getItem("idToken")
		if (!idToken) this.props.history.push("/launchscreen");
        console.log("this.state.selectedOrgs :", this.state.selectedOrgs);
		return (
			<div>
				<div className="chat-wrapper lifesherpa-communications" data-testid="lifesherpa-contacts">
					{openChat ? "" :
						<div className="row">
							<div className='col-2 col-xs-2  col-sm-2 col-md-3 col-lg-3 my-2'>
								{userRole && userRole !== 'Client' && this.state.selected !== "Call History" ? 
									<div className={`${(this.state.selectedOrgs.length > 0) ? "activity-filter-icon" : "activity-filter-icon-applied"}`}>
										<IconButton data-testid="activities-filter-icon-button" title="Filter By Orgnizations" className="text-white rounded-circle mr-2" onClick={() => this.handelShowFilter()}>
											<FilterAltIcon data-testid="activities-filter-enabled" className="filter-icon" fontSize="large" />
										</IconButton>
									</div>
									:<></>
								}
							</div>
							<div className='col-10 col-xs-10 col-sm-10 col-md-6 col-lg-6 my-2'>
								<div className="w-auto my-2 chat-tab-bar contacts-options" style={{ padding: 3 + 'px', borderRadius: 8 + "px" }} >
									<button data-testid="contacts-tab-button" className={`btn btn-sm my-auto ${this.state.selected === "contacts" ? "selectedTab" : "unSelectedTab"}`} onClick={() => this.setState({ selected: 'contacts' })}>
										Contacts
									</button>
									{userRole && userRole !== 'Client' ? 
										<button data-testid="groups-tab-button" className={`btn btn-sm my-auto ${this.state.selected === "Video Groups" ? "selectedTab" : "unSelectedTab"}`} onClick={() => this.setState({ selected: "Video Groups" })}>
											Video Groups
										</button>
										: <></>
									}
									<button data-testid="call-history-tab-button" className={`btn btn-sm my-auto ${this.state.selected === "Call History" ? "selectedTab" : "unSelectedTab"}`} onClick={() => this.setState({ selected: "Call History" })}>
										Call History
									</button>
								</div>
							</div>
							{ this.state.selected === "contacts" || this.state.selected === "Video Groups"  ?
								<div className='col-12 col-sm-12 col-md-3 col-lg-3 my-2'>
									<div className='px-2 search-contacts-field'>
										<form noValidate autoComplete="off">
											<TextField id="standard-search" 
												onChange={(e) => this.handelSearch(e)}
												label={this.state.selected === "Video Groups" ? "Search Video Groups" : "Search Contacts"}
												type="search" 
												value={search} 
												InputProps={{
												startAdornment: (
												<InputAdornment position="start">
													<SearchIcon color="primary"/>
												</InputAdornment>
												),
												endAdornment: ( search && search.length > 0 ?
													<InputAdornment position="end">
													<CloseIcon color="primary" onClick={() => this.handelClearSearch()} style={{cursor:"pointer"}}/>
													</InputAdornment> :  <InputAdornment position="end"> </InputAdornment>
												)
												}}
												variant="standard" 
											/>
										</form>
									</div>
								</div>
								:""
							}
							

						</div>
						// <div className='d-flex px-0 mb-2'>
						// 	{userRole && userRole !== 'Client' && this.state.selected !== "Call History" ? 
						// 		<div className={`${(this.state.selectedOrgs.length > 0) ? "activity-filter-icon" : "activity-filter-icon-applied"}`}>
						// 			<IconButton data-testid="activities-filter-icon-button" title="Filter By Orgnizations" className="text-white rounded-circle mr-2" onClick={() => this.handelShowFilter()}>
						// 				<FilterAltIcon data-testid="activities-filter-enabled" className="filter-icon" fontSize="large" />
						// 			</IconButton>
						// 		</div>
						// 		:<></>
						// 	}
							
							// <div className="w-auto mx-auto my-2 chat-tab-bar" style={{ padding: 3 + 'px', borderRadius: 8 + "px" }} >
							// 	<button data-testid="contacts-tab-button" className={`btn btn-sm my-auto ${this.state.selected === "contacts" ? "selectedTab" : "unSelectedTab"}`} onClick={() => this.setState({ selected: 'contacts' })}>
							// 		Contacts
							// 	</button>
							// 	{userRole && userRole !== 'Client' ? 
							// 		<button data-testid="groups-tab-button" className={`btn btn-sm my-auto ${this.state.selected === "Video Groups" ? "selectedTab" : "unSelectedTab"}`} onClick={() => this.setState({ selected: "Video Groups" })}>
							// 			Video Groups
							// 		</button>
							// 		: <></>
							// 	}
							// 	<button data-testid="call-history-tab-button" className={`btn btn-sm my-auto ${this.state.selected === "Call History" ? "selectedTab" : "unSelectedTab"}`} onClick={() => this.setState({ selected: "Call History" })}>
							// 		Call History
							// 	</button>
							// </div>
						// 	<div className="mx-2">									
								// <form noValidate autoComplete="off">
								// 	<TextField id="standard-search" 
								// 		onChange={(e) => this.handelSearch(e)}
								// 		label={"Search Contacts"}
								// 		type="search" 
								// 		value={search} 
								// 		InputProps={{
								// 		startAdornment: (
								// 		<InputAdornment position="start">
								// 			<SearchIcon color="primary"/>
								// 		</InputAdornment>
								// 		),
								// 		endAdornment: ( search && search.length > 0 ?
								// 			<InputAdornment position="end">
								// 			<CloseIcon color="primary" onClick={() => this.handelClearSearch()} style={{cursor:"pointer"}}/>
								// 			</InputAdornment> :  <InputAdornment position="end"> </InputAdornment>
								// 		)
								// 		}}
								// 		variant="standard" 
								// 	/>
								// </form>
						// 	</div>
							
						// </div>
					}


					<div /*className='rct-sherpa-scroll'*/>
						<div>
							{/* {this.state.openChat?'':
								<div className="row py-1" style={{background: "#008C95"}}><h2 className="mx-auto my-auto text-white">Communication</h2></div>
							} */}
							<LifeSherpaLoading loading={loading} />
							{this.state.selected === "contacts" && this.state.openChat === false && !loading ?
								<Contacts inProgressGroups={inProgressGroups} conversationTitle={conversationTitle} handleVideo={(chat) => this.handleVideo(chat)} selectChat={(e) => this.selectChat(e)} removeConversationTitle={this.removeConversationTitle} handleOpenVideoGroupRoom={this.handleOpenVideoGroupRoom} handleBack={() => this.handleBack()} selectedOrgs={this.state.selectedOrgs} search={search} />
								: this.state.selected === "Video Groups" && this.state.openChat === false ? <VideoGroups selectVideo={(e) => this.selectVideo(e)} openAddFrom={() => this.openAddFrom()}  selectedOrgs={this.state.selectedOrgs} search={search}/>
								: this.state.selected === "Call History" ? <VideoCallHistory infiniteScrollId="child-conatainer-infinitescroll"/>
									: this.state.selected === "chat" && !loading ? <UserChat fromNotification={fromNotification} openChat={this.state.openChat} selectedChat={this.state.selectedChat} handleVideo={(chat, token) => this.handleVideo(chat, token)} handleBack={() => this.handleBack()} />
										: selected === "openGroup" || selected === "newGroup" ? <NewGroup action={selected} selectedVideo={this.state.selectedVideo} handleBack={() => this.handleBack()} selectedVideoGroupDetails={this.selectedVideoGroupDetails} /> :
											''}
							{this.state.showFilter &&
								<ContactsFilterPopup
									showFilter={this.state.showFilter}
									selectedOrgs={this.state.selectedOrgs}
									clearFilter={this.clearFilter}
									applyFilter={this.applyFilter}
									handleCloseFilter={this.handleCloseFilter}
									panel="activities"
								/>
							}
						</div>
					</div>
				</div>
			</div>);
	}
}

const mapStateToProps = ({ Contacts, authUser, chatAppReducer }) => {
	const { userRole } = authUser
	const { videoToken, loading, selectedVideoChat, showVideoRoom, contactList } = Contacts
	const { selectedChatUsers, selectedUser } = chatAppReducer;
	return { videoToken, userRole, loading, selectedUser, selectedVideoChat, showVideoRoom, contactList };
};
export default withRouter(connect(mapStateToProps, {
	setSelectedChatUser, removeSelectedUser, selectMenuItem, hideVideoRoomPanel, showVideoRoomPanel, getVideoCallerInfoAndOpenConfirmation, sendGroupVideoCallResponse, networkActivity
})(withStyles(styles, { withTheme: true })(LifesherpaContacts)));
