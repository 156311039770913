/**
 * Chat Area Component
 */
 import React, { Component } from 'react';
 import MessageBlock from './MessageBlock';
 import { FormGroup, Input } from 'reactstrap';
 import { Scrollbars } from 'react-custom-scrollbars';
 import { connect } from 'react-redux';
 import Button from '@mui/material/Button';
 import IconButton from '@mui/material/IconButton';
 import Menu from '@mui/material/Menu';
 import MenuItem from '@mui/material/MenuItem';
 import { withRouter } from 'react-router-dom';
 import MenuIcon from '@mui/icons-material/Menu';
 import RctCollapsibleChatCard from 'Components/RctCollapsibleCard/RctCollapsibleChatCard';
 import MoreVertIcon from '@mui/icons-material/MoreVert';
 import AddIcon from '@mui/icons-material/Add';
 import SendIcon from '@mui/icons-material/Send';
 import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
 import LifeSherpaLoading from 'Components/LifeSherpaLoading';

 import ArrowBackIcon from '@mui/icons-material/ArrowBack';
 import MiniChatSection from "./MiniChatSection";
 // actions
 import {activitiesOpen, chatOpen, sendMessageToUser, clearUnconfirmData, removeSelectedChatUser} from 'Actions'
 
 // app layouts
 import { getAppLayout } from 'Helpers/helpers';
 
 class ChatSections extends Component {
      state ={
          update:false,
      }

     render() {
         const { selectedUser, admin_photo_url, isChatOpen, selectedMember, isActivitiesOpen, selectedChatUsers, userUpdatedTolist, windowWidth } = this.props;
         console.log("chat sections -----> ",selectedChatUsers );
         
         if(!selectedChatUsers) {
             return "";
         } else {
             return (
                 <React.Fragment>
                    {selectedChatUsers && selectedChatUsers.length > 0 && 
                    selectedChatUsers.map((user,key)=> {
                        let panelWidth = 339;
                        let right = key * panelWidth;
                        let availableWidth = windowWidth - right;
                        if(availableWidth >= panelWidth && user) {
                            if(availableWidth < (panelWidth * 2)) {
                                let lastIndex = selectedChatUsers.length - 1;
                                 user =   selectedChatUsers[lastIndex] ? selectedChatUsers[lastIndex] : user;
                            }
                            return (
                                <div key={user.userId} className="mini-chat-panel-sections" style={{right: right+"px"}}>
                                    <div className="chat-sections" >
                                        <MiniChatSection
                                            selectedUser= {user}
                                            key={user.userId}
                                        />
                                    </div>
                                </div>
                           )
                        } else {
                            return ""
                        }  
                    }     
                    )}
               </React.Fragment> 
             )
         }
         
     }
 }
 
 const mapStateToProps = ({ chatAppReducer,GroupList, settings}) => {	
     const { selectedUser, admin_photo_url, selectedMember, selectedChatUsers , userUpdatedTolist} = chatAppReducer;
     const { screenThemeClass, popupThemeClass } = settings; 

     return { selectedUser, admin_photo_url, selectedMember, screenThemeClass, selectedChatUsers, userUpdatedTolist }
 }
 
 export default withRouter(connect(mapStateToProps, {
     sendMessageToUser, activitiesOpen, chatOpen, clearUnconfirmData, removeSelectedChatUser
 })(ChatSections));
 