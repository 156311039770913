import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateContactsList, hideContactsLoading, removeSelectedChatUser, setSelectedChatUser, removeSelectedUser, showFullChatPanelForSelectedUser, showVideoRoomPanel } from 'Actions';
import LifeSherpaLoading from '../LifeSherpaLoading';
import ContactListItem from "./ContactListItem";
import UserChat from "./UserChat";
import InProgressGroup from "./InProgressGroup";
import WarningDialog from "../WarningDialog";
import AppConfig from "../../constants/AppConfig"
import SweetAlert from 'react-bootstrap-sweetalert'
import { valuesIn } from 'lodash';


class Contacts extends Component {
    state = { 
        selectedChat:{},
        laoding:false,
        chatOpen:false,
        openChat:false,
        selectedChat: null,
        showWarning: false,
        warningText: "",
        fromNotification: false
     }

    componentDidMount = async () =>  {
        let {contactList, loading, twilioClient, conversationTitle} = this.props;
        if(!(contactList && contactList.length > 0) && !loading && !twilioClient) {
           // this.props.getHelpContactsWithChannel();
        } else {
            console.log(" conversationTitle ->", conversationTitle);
            if(conversationTitle) {
                this.handleShowNotificationChat(conversationTitle)
            } 
        }
    }

    handleShowNotificationChat = (conversationTitle) => {
        console.log( " OnScreen notification selected -->")
        let {contactList } = this.props;
        let notificationUser = contactList.find(user => user.conversation && user.conversation.channelState ? user.conversation.channelState.uniqueName  == conversationTitle : false);
        console.log(" User who received notification --> ", notificationUser);
        this.handleSelectChatForNotification(notificationUser)
        this.props.removeConversationTitle();
    }

    removeSelectedChatUser = (userId) => {
        console.log(" Chat closed for user ->",userId);
        let {selectedChatUsers} = this.props;
        let index = selectedChatUsers.findIndex(user => user.userId == userId);
        if(index >= 0) {
            selectedChatUsers.splice(index,1);
            this.props.removeSelectedChatUser(selectedChatUsers);
        }
    }

    handleSelectChat=(value)=>{
        if(value && value.status === "Available") {
            this.opneSelectedChat(value)
        } else {
            let warningText = `${value.name} is currently unavailable. If you need immediate assistance, please tap Cancel and select another contact. Tap Ok to continue.`;
            this.setState({showWarning: true, warningText: warningText, selectedChat: value})
        }
       
    }

    handleSelectChatForNotification=(value)=>{
        if(value) {
            this.setState({fromNotification: true})
            this.opneSelectedChat(value)
        }
    }

    handleAcceptUnAvailableWarning = () => {
        let {selectedChat} = this.state;
        this.opneSelectedChat(selectedChat);
        this.handleCloseWarningAlert();
    }

    handleCloseWarningAlert = () => {
        this.setState({showWarning: false, warningText: ""})
    }
   
    opneSelectedChat = (value) => {
        const selectedOrgId = (value.assignedOrganizations && Object.keys(value.assignedOrganizations)[0]);
        if(selectedOrgId) {
            localStorage.setItem('selectedOrgId', selectedOrgId);
        }
        this.removeSelectedChatUser(value.userId);
        this.props.setSelectedChatUser(value);
        if(value.conversation) {
            this.setState({openChat:true,selectedChat: value });
            this.props.selectChat(value)
        } else {
            console.log(" Token generating for --->",value);
            this.props.selectChat(value);
        }  
    }

    handleBack = () => {
        this.setState({openChat:false,selectedChat: null, fromNotification: false });
        //this.props.removeSelectedUser();
        this.props.handleBack();
    }

    applySearchFilter = (list) => {
        const {search} = this.props;
        if(search && search.length > 2 && list && list.length > 0) {
            return list.filter((item) => item.name && item.name.toLowerCase().includes(search.toLowerCase()))
        }
        return list;
    }

    render() { 
        let {contactList, loading, conversationTitle, inProgressGroups, userRole, selectedOrgs } = this.props;
        let filteredList = [];
        if(contactList && contactList.length) {
            filteredList = selectedOrgs && selectedOrgs.length ? contactList.filter(user => {
                return Object.keys(user.assignedOrganizations).findIndex(orgId => user.assignedOrganizations[orgId] && selectedOrgs.includes(orgId)) !== -1
            })  : contactList;
            filteredList = this.applySearchFilter(filteredList);
        }
        
        let {openChat, selectedChat, showWarning, warningText, fromNotification } = this.state;
        return ( 
            <div data-testid="contact-list-component">
                {
                loading ?
                  <LifeSherpaLoading loading={loading}/>
                 : openChat ?
                 <UserChat fromNotification={fromNotification} handleVideo={this.props.handleVideo} openChat={openChat} selectedChat={selectedChat}  handleBack={()=>this.handleBack()} />
                 :
                 <ul className="list-unstyled m-0 list-sherpa-activity px-0 pt-1 mb-4">
                    {userRole && userRole == 'Client' && inProgressGroups && inProgressGroups.length > 0 && inProgressGroups.map((inProgressGroup,index) => 
                        <InProgressGroup
                            key = {index+"inprogressgroup"}
                            inProgressGroup = {inProgressGroup}
                            handleOpenVideoGroupRoom={this.props.handleOpenVideoGroupRoom}
                        />
                    )}
                    {!conversationTitle && filteredList.length>0 ?filteredList.map((list,index)=>(
                        <ContactListItem
                            key={index}
                            client={list}
                            handleSelectChat={this.handleSelectChat}
                            keyIndex={index}
                            openChat={openChat}
                            />
                        )):
                    <div className='col-12 mt-50'>
                        <h2 className="text-secondary mx-auto" style={{marginTop:'100px'}}>Contact list is empty.</h2>
                    </div>
                    }
                </ul>}
                {/* <WarningDialog
                    showWarning = {showWarning}
                    warningText = {warningText}
                    cancelText = {"Cancel"}
                    acceptText = {"Ok"}
                    handleCancel = {() => this.handleCloseWarningAlert()}
                    handleAccept = {() => this.handleAcceptUnAvailableWarning()}
                /> */}
                {showWarning && warningText ?
                    <SweetAlert
                        custom
                        btnSize="sm"
                        closeOnClickOutside={false}
                        customClass="warningText"
                        confirmBtnText="OK"
                        cancelBtnText="Cancel"
                        confirmBtnBsStyle="warning"
                        cancelBtnBsStyle="default"
                        title={`${selectedChat.name} is currently unavailable`}
                        showCancel
                        onConfirm={() => this.handleAcceptUnAvailableWarning()}
                        customIcon={AppConfig.lsIconLogo}
                        onCancel={() => this.handleCloseWarningAlert()}
                    >
                        <div className="sweet-alert-text">If you need immediate assistance, please tap Cancel and select another contact. Tap OK to continue.</div>
                    </SweetAlert>
                    : <></>
                }
                
            </div>
        );
    }
}

const mapStateToProps = ({Contacts, chatAppReducer, authUser}) => {
    let {contactList, loading, chatToken, twilioClient} = Contacts;
    const { selectedChatUsers, selectedUser } = chatAppReducer;
    const {userRole}=authUser
	return {contactList,loading, chatToken, twilioClient, selectedChatUsers, userRole};
};

export default connect(mapStateToProps, {
    updateContactsList, 
    hideContactsLoading, 
    removeSelectedChatUser, 
    setSelectedChatUser, 
    removeSelectedUser, 
    showFullChatPanelForSelectedUser, 
    showVideoRoomPanel,
})(Contacts);