/**
 * React-Chartjs2
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Doughnut from './doughnut';
import LineChart from './line-chart';
import Bubble from './bubble';
import Bar from './bar';
import Polar from './polar';
import Pie from './pie';
import Radar from './radar';
import HorizontalBar from './horizontal-bar';
import ScatterChartComponent from './scatter'
import StackedChartComponent from './stackedChart'
import { Label } from 'reactstrap'
import DatePickers from '../../components/Pickers/DatePickers'
import moment from 'moment'
import IntlMessages from "../../util/IntlMessages"
import ChartConfig from '../../constants/chart-config';
import { Input } from 'reactstrap'
import LifeSherpaLoading from '../LifeSherpaLoading';
import MatButton from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
//actions
import { getSelectedGroup, getChartsList, getChartById, getFilterChartById, showActivityChartsLoading, setUserSelected, setSelectedMemberId, selectMenuItem } from '../../actions'
import ActivitiesFilterPopup from '../Widgets/ActivitiesFilterPopup';
import { IconButton } from '@mui/material';
import { FilterAlt } from '@mui/icons-material';

// rct card box
import RctCollapsibleCard from '../../components/RctCollapsibleCard/RctCollapsibleCard';

class UserModuleCharts extends Component {
	constructor(props) {
		super(props)
		this.state = {
			fromDate: moment().subtract(1, 'years').format('MMM DD YYYY'),
			toDate: moment().format('MMM DD YYYY'),
			chartParams: {}
		}
		this.getChartData = this.getChartData.bind(this)
		this.handleFromDateChange = this.handleFromDateChange.bind(this)
		this.handleToDateChange = this.handleToDateChange.bind(this)
		this.getChartDataById = this.getChartDataById.bind(this)
	}

	componentDidMount() {
		this.fetchDefaultReports()
	}

	fetchDefaultReports() {
		let chartParams = {}
		let idToken = localStorage.getItem('idToken')
		let module = "users"
		let orgId = localStorage.getItem("orgId")
		let id = localStorage.getItem("auth_uid");
		const {selectedMember, groupMembers, selectedMemberId} = this.props;

		if (groupMembers && selectedMember && selectedMemberId && selectedMemberId !== id) {
			id = selectedMemberId
			orgId = selectedMember.organization
		} else if(!selectedMemberId) {
			this.props.setSelectedMemberId(id);
		}

		chartParams = { id, idToken, orgId, module }
		this.setState({ chartParams })
		// this.props.selectMenuItem(1);
		this.props.getChartsList(chartParams.id, chartParams.orgId, chartParams.idToken, chartParams.module);
	}

	formatSelectedGroups(selectedGroup){
		let multiSelectedGroup = []
		if (Array.isArray(selectedGroup) && selectedGroup.length > 0){
			selectedGroup.map((group) => {
				multiSelectedGroup.push(group.id)
			})
		}
		return multiSelectedGroup
	}

	componentWillUnmount(){
		const { groups, userRole, selectedGroup } = this.props;
		if (userRole !== 'Client' && groups && groups.length > 0 && selectedGroup === 'all'){
			this.props.getSelectedGroup(groups[0].id, selectedGroup);
		}
	}

	getChartData(chartData) {
		const labels = chartData.labels
		const datasets = []
		chartData.series.map((item, key) => {
			const innerData = {
				label: item,
				fill: false,
				backgroundColor: ChartConfig.colorArray[key],
				borderColor: ChartConfig.colorArray[key],
				borderWidth: 1,
				hoverBackgroundColor: ChartConfig.colorArray[key],
				hoverBorderColor: ChartConfig.colorArray[key],
				data: chartData.data[key]
			}
			datasets.push(innerData)
		})
		return { labels, datasets }
	}

	handleFromDateChange(date) {
		console.log("from date --> ", date)
		date = moment(date);
		const filterData = { fromDate: moment(date).format('YYYY-MM-DD HH:mm:ss'), toDate: moment(this.state.toDate, 'MMM DD YYYY').add(1, 'days').format('YYYY-MM-DD HH:mm:ss') }
		this.setState({ fromDate: date, filterData })
		this.props.usersCharts && this.props.usersCharts.map(async item => {
			await this.filterChartData(item.chartId, filterData)
		})
	}
	handleToDateChange(date) {
		console.log("to date --> ", date)
		date = moment(date);
		const filterData = { fromDate: moment(this.state.fromDate, 'MMM DD YYYY').format('YYYY-MM-DD HH:mm:ss'), toDate: moment(date).add(1, 'days').format('YYYY-MM-DD HH:mm:ss') }
		this.setState({ toDate: date, filterData })
		this.props.usersCharts && this.props.usersCharts.map(async item => {
			await this.filterChartData(item.chartId, filterData)
		})
	}

	async filterChartData(chartId, filterData) {
		let { chartParams } = this.state
		chartParams.idToken = localStorage.getItem("idToken")
		if (chartParams.id && chartParams.orgId && chartParams.module) {
			await this.props.getFilterChartById(chartParams.id, chartParams.orgId, chartParams.idToken, chartParams.module, chartId, filterData)
		}
	}
	async getChartDataById(chartId) {
		const filterData = { fromDate: moment(this.state.fromDate, 'MMM DD YYYY').format('YYYY-MM-DD HH:mm:ss'), toDate: moment(this.state.toDate, 'MMM DD YYYY').add(1, 'days').format('YYYY-MM-DD HH:mm:ss') }
		let { chartParams } = this.state
		chartParams.idToken = localStorage.getItem("idToken")
		if (chartParams.id && chartParams.orgId && chartParams.module) {
			await this.props.getFilterChartById(chartParams.id, chartParams.orgId, chartParams.idToken, chartParams.module, chartId, filterData)
		}
	}

	// onClose() {
	// 	localStorage.setItem('selectedIndex', JSON.stringify(0))
	// 	this.props.history.push("/app/lsdashboard")
	// }
	handleUserSelection(e) {
		this.props.setSelectedMemberId(e.target.value)
		let { chartParams } = this.state
		chartParams.idToken = localStorage.getItem("idToken")
		chartParams.id = e.target.value
		if (e.target.value === 'all') {
			chartParams.module = 'groups'
			chartParams.id = Array.isArray(this.props.selectedGroup) && this.props.selectedGroup.length > 0 ? this.props.selectedGroup[0].id : this.props.selectedGroup
			chartParams.orgId = Array.isArray(this.props.selectedGroup) && this.props.selectedGroup.length > 0 ? this.props.selectedGroup[0].orgId : localStorage.getItem("orgId")
		} else {
			chartParams.module = 'users'
			const id = localStorage.getItem("auth_uid");
			if (e.target.value !== id) {
				const {groupMembersDetails} = this.props;
				const index = groupMembersDetails ? groupMembersDetails.findIndex(user => user.userId === e.target.value) : -1;
				if(index !== -1) {
					const user = groupMembersDetails[index];
					chartParams.orgId = user.organization;
					if(this.props.groupMembers[user.groupId]) {
						this.props.setUserSelected(this.props.groupMembers[user.groupId][e.target.value]);
					}
				}
			}
		}		
		this.setState({ chartParams })
		this.props.getChartsList(chartParams.id, chartParams.orgId, chartParams.idToken, chartParams.module)
	}
	getGoupMembers(memebrs) {
		let membersList = []
		let id = localStorage.getItem("auth_uid");
		membersList.push({ id, "value": "My Reports" })
		let {groupMembersDetails} = this.props
		  groupMembersDetails && groupMembersDetails.map((user,key)=> {
			const index = membersList.findIndex(member => member.id === user.userId);
			if(index === -1) {
				membersList.push({ "id": user.userId, "value": user.name || "Display Name"})
			}
		  })
		// if (memebrs && !groupMembersDetails) {
		// 	Object.keys(memebrs).map(item => {
		// 		const fistname = memebrs[item].user && memebrs[item].user.firstName ? memebrs[item].user.firstName : 'Display'
		// 		const lastname = memebrs[item].user && memebrs[item].user.lastName ? memebrs[item].user.lastName : 'Name'
		// 		const displayname = memebrs[item].user && memebrs[item].user.displayName ? memebrs[item].user.displayName : `${fistname} ${lastname}`
		// 		mebersList.push({ "id": item, "value": displayname })
		// 	})
		// }
		return membersList
	}

	handleGroupFilter(e) {
		
		//Only for Single organization 
		const {groups} = this.props;
		let { chartParams } = this.state
		chartParams.idToken = localStorage.getItem("idToken")
		const index = groups.findIndex(group => group.id === e.target.value);
		if(index !== -1) {
			this.props.getSelectedGroup([groups[index]], this.props.selectedGroup);
			chartParams.orgId = groups[index].orgId || localStorage.getItem("orgId");
		} else {
			chartParams.orgId = groups[index].orgId || localStorage.getItem("orgId");
			this.props.getSelectedGroup(e.target.value, this.props.selectedGroup);
		}
		
		let id = localStorage.getItem("auth_uid");
		this.props.setSelectedMemberId("all")
		chartParams.id = e.target.value;
		chartParams.module = "groups";
		this.setState({ chartParams })
		this.props.getChartsList(chartParams.id, chartParams.orgId, chartParams.idToken, chartParams.module)

		//Enable to support Multi-organization  
		// const {selectedGroup} = this.props
		// const {groups} = this.props;
		// let { chartParams } = this.state
		// const mergedGroupIds = e.target.value
		// if (mergedGroupIds && mergedGroupIds.length > 0) {
		// 	const currentSelectedId = mergedGroupIds[mergedGroupIds.length-1]
		// 	const mergedSelectedGroups = groups.filter(group => mergedGroupIds.includes(group.id))
		// 	const index = groups.findIndex(group => group.id == currentSelectedId);
		// 	this.props.getSelectedGroup(mergedSelectedGroups, selectedGroup);
		// 	chartParams.orgId = index !== -1 ? groups[index].orgId : localStorage.getItem("selectedOrgId");
		// 	this.props.setSelectedMemberId("all")
		// 	chartParams.id = currentSelectedId
		// 	chartParams.module = "groups";
		// 	this.setState({ chartParams })
		// 	this.props.getChartsList(chartParams.id, chartParams.orgId, chartParams.idToken, chartParams.module)
		// } else {
		// 	this.fetchDefaultReports()
		// }
		
	}

	handleOpenFilter(){
        const {openOrgFilter} = this.state
        this.setState({openOrgFilter: !openOrgFilter})
    }

	render() {
		const { loading, usersCharts, groupMembers, selectedMemberId, loadingComponent, authUser, userRole, profile, clientPanelOrgFiltered } = this.props
		const { groups, selectedGroup } = this.props;
		const chartData = usersCharts;
		const {openOrgFilter} = this.state;
		const members = this.getGoupMembers(groupMembers);
		const displayName = profile ? profile.nickname || profile.name || (profile.given_name && profile.family_name ? profile.given_name + " " + profile.family_name : profile.given_name || profile.family_name ) : 'Display Name'
		//enable for multi-organization support
		// const multiSelectedGroup = this.formatSelectedGroups(selectedGroup)

		return (
			<>
				{
					loading && !usersCharts &&
					<LifeSherpaLoading loading={loading} />

				}
				<div data-testid="reports-component"  className="row">
					<RctCollapsibleCard 
						headingCustomClasses="bg-lifesherpa"
						heading="Filter  Chart By Date Range"
						colClasses="col-sm-12 col-md-12 col-lg-12"
						customClasses="overflow-hidden"
						contentCustomClasses="user-module-chart-feature"
						>
						<div className="row" >
							<div className={`${userRole !== 'Client' ? 'col-xl-4 col-lg-4' : "col-xl-6 col-lg-6"} col-sm-12 pt-3 col-md-6`}>
								<div className="p-2 list-card">
									<div className="d-flex align-items-center">
										<div className="pr-2"><DatePickers testid="data-picker-from-data" value={this.state.fromDate} label={<IntlMessages id="chart.fromDate" />}  handleOnDateChange={this.handleFromDateChange.bind(this)} /></div>
										<DatePickers testid="data-picker-to-data"  value={this.state.toDate} label={<IntlMessages id="chart.toDate" />}  handleOnDateChange={this.handleToDateChange.bind(this)} />
									</div>
								</div>
							</div>
							{userRole !== 'Client' && 
								<div className="col-sm-12 pt-3  col-md-6 col-xl-4 col-lg-4 align-items-center">
									<div className="p-2 d-flex list-card">
										{/* enable to support multi-organizatio support 
											<div className={`d-flex ${clientPanelOrgFiltered && clientPanelOrgFiltered.length > 0 ? "activity-filter-icon" : "activity-filter-icon-applied"} mr-2`}>
												<IconButton data-testid="orgId-filter-icon-button" title="Filter By OrgId" className="text-white rounded-circle mr-2" onClick={() => this.handleOpenFilter()}>
													<FilterAlt data-testid="orgId-filter-enabled" className="filter-icon" fontSize="large"/>
												</IconButton>
											</div> 
										*/}
										{
											<FormControl fullWidth variant="standard">
												<InputLabel htmlFor="age-simple">Groups</InputLabel>
												<Select 
													data-testid="select-group-dropdown"
													// value={multiSelectedGroup}
													value={Array.isArray(selectedGroup) && selectedGroup.length ? selectedGroup[0].id : selectedGroup ? selectedGroup : ""}
													onChange={(e) => this.handleGroupFilter(e)}
													inputProps={{ name: 'Select Group', id: 'selct-group'}}
													// multiple
													>
														<MenuItem disabled value=""><em>Select Group</em></MenuItem>
														{groups && groups.map((obj, key) => (
															<MenuItem key={key} value={obj.id}>{obj.name}</MenuItem>
														))}
														{/* <MenuItem value={"all"}>
															All Groups Of Default Organization
														</MenuItem> */}
												</Select>
											</FormControl>
										}
									</div>
								</div>
							}
							<div className={`${userRole !== 'Client' ?  "col-xl-4 col-lg-4" : "col-xl-6 col-lg-6"} col-sm-12 pt-3 col-md-6 align-items-center`}>
								<div className="p-2 list-card">
									<FormControl fullWidth variant="standard">
										<InputLabel htmlFor="age-simple">Clients</InputLabel>
										<Select data-testid="select-client-dropdown"
											value={selectedMemberId ? selectedMemberId : ""}
											onChange={this.handleUserSelection.bind(this)}
											inputProps={{ name: "labels", id: "task-labels" }}>
											<MenuItem disabled value=""><em>Select Client</em></MenuItem>
											{members.length > 0 && members.map((user, key) => (
												<MenuItem key={user.id} value={user.id}>
													{user.value == "My Reports" ? displayName : user.value}
												</MenuItem>
											))}
											{userRole !== 'Client' && <MenuItem value={"all"}>
												All Clients Of Selected Group
											</MenuItem>}

										</Select>
									</FormControl>
								</div>
							</div>
						</div>
					</RctCollapsibleCard>
					{chartData && chartData.length > 0 ? chartData.map((userChart, key) => (
						<div data-testid={`user-report-chart${key}`} key={key} className="col-sm-12 col-md-12 col-lg-6">
							{userChart.class === 'doughnut' && <RctCollapsibleCard customClasses="overflow-hidden" headingCustomClasses="bg-lifesherpa " heading={userChart.title}>
								<Doughnut data={this.getChartData(userChart)} getChartData={this.getChartDataById} chartId={userChart.chartId} loading={loadingComponent[userChart.chartId] ? true : false} />
							</RctCollapsibleCard>}
							{userChart.class === 'line' && <RctCollapsibleCard customClasses="overflow-hidden" headingCustomClasses="bg-lifesherpa " heading={userChart.title} >
								<LineChart data={this.getChartData(userChart)} getChartData={this.getChartDataById} chartId={userChart.chartId} loading={loadingComponent[userChart.chartId] ? true : false} />
							</RctCollapsibleCard>}
							{userChart.class === 'bubble' && <RctCollapsibleCard customClasses="overflow-hidden" headingCustomClasses="bg-lifesherpa " heading={userChart.title}>
								<Bubble data={this.getChartData(userChart)} getChartData={this.getChartDataById} chartId={userChart.chartId} loading={loadingComponent[userChart.chartId] ? true : false} />
							</RctCollapsibleCard>}
							{userChart.class === 'bar' && !userChart.options && <RctCollapsibleCard customClasses="overflow-hidden" headingCustomClasses="bg-lifesherpa " heading={userChart.title}>
								<Bar data={this.getChartData(userChart)} getChartData={this.getChartDataById} chartId={userChart.chartId} loading={loadingComponent[userChart.chartId] ? true : false} />
							</RctCollapsibleCard>}
							{userChart.class === 'bar' && userChart.options && userChart.options.scales && userChart.options.scales.xAxes && userChart.options.scales.yAxes && userChart.options.scales.xAxes[0].stacked && userChart.options.scales.yAxes[0].stacked && <RctCollapsibleCard customClasses="overflow-hidden" headingCustomClasses="bg-lifesherpa " heading={userChart.title}>
								<StackedChartComponent data={this.getChartData(userChart)} getChartData={this.getChartDataById} chartId={userChart.chartId} loading={loadingComponent[userChart.chartId] ? true : false} />
							</RctCollapsibleCard>}
							{userChart.class === 'polar' && <RctCollapsibleCard customClasses="overflow-hidden" headingCustomClasses="bg-lifesherpa " heading={userChart.title}>
								<Polar data={this.getChartData(userChart)} getChartData={this.getChartDataById} chartId={userChart.chartId} loading={loadingComponent[userChart.chartId] ? true : false} />
							</RctCollapsibleCard>}
							{userChart.class === 'pie' && <RctCollapsibleCard customClasses="overflow-hidden" headingCustomClasses="bg-lifesherpa " heading={userChart.title}>
								<Pie data={this.getChartData(userChart)} getChartData={this.getChartDataById} chartId={userChart.chartId} loading={loadingComponent[userChart.chartId] ? true : false} />
							</RctCollapsibleCard>}
							{userChart.class === 'radar' && <RctCollapsibleCard customClasses="overflow-hidden" headingCustomClasses="bg-lifesherpa " heading={userChart.title}>
								<Radar data={this.getChartData(userChart)} getChartData={this.getChartDataById} chartId={userChart.chartId} loading={loadingComponent[userChart.chartId] ? true : false} />
							</RctCollapsibleCard>}
							{userChart.class === 'horizontalbar' && <RctCollapsibleCard customClasses="overflow-hidden" headingCustomClasses="bg-lifesherpa " heading={userChart.title}>
								<HorizontalBar data={this.getChartData(userChart)} getChartData={this.getChartDataById} chartId={userChart.chartId} loading={loadingComponent[userChart.chartId] ? true : false} />
							</RctCollapsibleCard>}
							{userChart.class === 'scatter' && <RctCollapsibleCard customClasses="overflow-hidden" headingCustomClasses="bg-lifesherpa " heading={userChart.title}>
								<ScatterChartComponent data={this.getChartData(userChart)} getChartData={this.getChartDataById} chartId={userChart.chartId} loading={loadingComponent[userChart.chartId] ? true : false} />
							</RctCollapsibleCard>}
						</div>
					)) :
						<RctCollapsibleCard customClasses="overflow-hidden" colClasses="col-sm-12 col-md-12 col-lg-12">
							<Label data-testid={`no-chart-data-available`}>There is no chart data available in this module, please contact to administrator for more info.</Label>
						</RctCollapsibleCard>
					}
					{openOrgFilter ?
						<ActivitiesFilterPopup
							title={"Organization Filter"}
							showFilter={openOrgFilter}
							handleCloseFilter={this.handleOpenFilter.bind(this)}
							panel="client"
						/>
						: <></>
                    }
				</div>
			</>
		);
	}
}

// map state to props
const mapStateToProps = ({ settings, Charts, GroupList, chatAppReducer, authUser, clientsList }) => {
	const { profile, user, userRole } = authUser
	const { popupThemeClass } = settings
	const { selectedMemberId, selectedMember } = chatAppReducer
	const { usersCharts, loading, loadingComponent } = Charts
	const { selectedGroup, groupMembers, groups ,groupMembersDetails} = GroupList;
	const {clientPanelOrgFiltered} = clientsList


	let datas = []
	usersCharts && usersCharts.length > 0 ? usersCharts.map(item => { datas.push(item.data) }) : []
	return {  profile, groups, authUser, popupThemeClass, usersCharts, loading, datas, selectedGroup, groupMembers, selectedMemberId, loadingComponent, userRole, groupMembersDetails, clientPanelOrgFiltered}
}

export default connect(mapStateToProps, {
	getChartsList,
	getChartById,
	getFilterChartById,
	showActivityChartsLoading,
	setUserSelected,
	setSelectedMemberId,
	getSelectedGroup,
	selectMenuItem
})(UserModuleCharts);

