import React, { Component } from 'react';
import { connect } from 'react-redux';
import {setReportAsRead,getResourceList,getcategories,getKeywords, getPostById, setResorcesAndCategories, setResorcesSelectedList, clearResourcesData, setPostOpenHistory, setResourcesFilterItemsCount} from '../../actions'
import {IconButton, Button , Badge } from '@mui/material';
import Slider from "react-slick";
import moment from 'moment' 
import FileDownload from "js-file-download";
import { Scrollbars } from 'react-custom-scrollbars';
import { withStyles } from '@mui/styles';
import ResourcesFilter from './ResourcesFilter';
import LifeSherpaLoading from '../LifeSherpaLoading';
import Grid from '@mui/material/Grid';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import WidgetServices from "../../services/widgetServices";
import PostView from "./PostView";
import { getURLFromText, openPopup } from '../../helpers/helpers';
import Linkify from './Linkify';
import { database } from '../../firebase';
import {ref, onValue, off} from "firebase/database"

const styles = theme => ({
	tabsRoot: {
	    minHeight: '40px',
        height: '40px',
    
		
	},
    tabRoot : {
        minHeight: '40px',
        height: '40px',
        backgroundColor:'#008C95',
        borderRadius:6+"px",
        marginRight:"3px",
        fontSize:"14px",
        minWidth:"50px"
    }
	
});

class BolletinBoard extends Component {
    state = { 
        showFilter:false,
        sort:'asc',
        collection:'',
        showImg:false,list:null,
        keywords: '',
        activeIndex:0,
        showFilteredButton: false,
        windowWidth:window.innerWidth,
        windowHeight: window.innerHeight,
        iframeLoading: true
     }
    
    updateDimensions = () => {
		this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
	}

    componentDidMount() {
        console.log("%c[BolletinBoard]%c Rendered user all resources panel at home page","color:black","color:green");
        let testing= localStorage.getItem("testing");
        if(!testing) {
           // console.log('--------Data has been cleared--------')
            this.props.clearResourcesData()
        }
        const widgetServices = new WidgetServices()
        if (widgetServices.isWidgetApp()) {
            window.addEventListener("resize", this.updateDimensions);
        } else {
            const orgId = localStorage.getItem("orgId")
            const auth_uid = localStorage.getItem("auth_uid")
            const url = `Realtime/${orgId}/Users/${auth_uid}/resourcesChangeTimestamp`
            const refUrl = ref(database, url);
            // Fetch data from Firebase Realtime Database
            onValue(refUrl, (snapshot) => {
                const resourcesChangeTimestamp = snapshot.val() || {};
                console.log("resourcesChangeTimestamp: ", resourcesChangeTimestamp);
                this.props.setResorcesSelectedList(null);
                this.props.getResourceList('','new', '')
                this.props.getcategories()
                this.props.getKeywords()
                
            });    
        }
    }
    componentWillUnmount() {
        const widgetServices = new WidgetServices()
        if (widgetServices.isWidgetApp()){
            window.removeEventListener("resize", this.updateDimensions);
        } else {
            const orgId = localStorage.getItem("orgId")
            const auth_uid = localStorage.getItem("auth_uid")
            const url = `Realtime/${orgId}/Users/${auth_uid}/resourcesChangeTimestamp`
            const refUrl = ref(database, url);
            off(refUrl)
        }
	}

    downloadFile=(url,name)=>{
          FileDownload(url, name);
    }
    handleReport=(value)=>{
        //this.props.Resources.categories[i].selected=true
        let {categories} = this.props
        const i = categories.findIndex(cat => cat.id === value.id && cat.organization === value.organization)
        if(categories && categories[i].selected) categories[i].selected=false
        else categories[i].selected=true
       // console.log(i,this.state.collection,this.props.Resources.categories[i]);
        this.setState({collection: categories[i].selected})
    }
    handleKeywords=(value)=>{
        let {keywords} = this.props
        const i = keywords.findIndex(keyword => keyword.name === value.name)
        console.log("Selected keyword & index: ", value, i, keywords)
        if(keywords && keywords[i].selected) keywords[i].selected=false
        else keywords[i].selected=true
        this.setState({collection: keywords[i].selected})
    }
    handleSort=(value)=>{
        if(this.state.sort===value) this.setState({sort:''})
        else this.setState({sort:value})
    }
    applyFilter=(orgIds)=>{
        let collection=''
        let keywords=''
        let selectedCounts = 0
        let allCounts = 0
        this.props.keywords && this.props.keywords.map((cat,i)=>{
            if(cat.selected) {
                keywords=keywords+cat.name+','
                selectedCounts += cat.totalCount ? cat.totalCount : 0
            }
            allCounts += cat.totalCount ? cat.totalCount : 0
        })
        this.props.categories && this.props.categories.map((cat,i)=>{
            if(cat.selected) {
                collection=collection+cat.id+','
                selectedCounts += cat.totalCount ? cat.totalCount : 0
            }
            allCounts += cat.totalCount ? cat.totalCount : 0
        })
        selectedCounts = collection === '' && keywords === '' ? allCounts : selectedCounts
        const list = {name: "Filtered", totalCount: selectedCounts}
        this.props.getResourceList(this.state.sort, collection, keywords, orgIds, true);
        //this.props.setResorcesSelectedList(list);
        this.setState({showFilter: false, showFilteredButton: true , keywords})
    }
    
    clearFilter=()=>{
        //const orgIds = localStorage.getItem("orgIds")? localStorage.getItem("orgIds").split(",") : null
        this.props.categories.map((cat,i)=>{
            this.props.categories[i].selected=false
        })
        this.props.keywords.map((cat,i)=>{
            this.props.keywords[i].selected=false
        })
        let {newReportAvailable,categories} = this.props;
       
        if(!newReportAvailable && categories.length > 0) {
            this.props.getResourceList('',categories[0].id, '');
            this.props.setResorcesSelectedList(categories[0]);
        }
        else {
            this.props.setResorcesSelectedList(null);
            this.props.getResourceList('','new', '');
        }
        this.props.setResourcesFilterItemsCount(0);
        this.setState({showFilter: false, sort:'',collection:'', showFilteredButton: false, keywords: ''})   
    }
    handleList=(value, organization)=>{
        //const orgIds = localStorage.getItem("orgIds")? localStorage.getItem("orgIds").split(",") : null;
        if(organization) {
            this.props.getResourceList('',value, '', [organization])
        } else {
            this.props.getResourceList('',value, '')
        }
        if(value==='') {
            this.props.setResorcesSelectedList(null);
        } else {
            let list=this.props.categories.find((cat)=>cat.id===value)
            this.props.setResorcesSelectedList(list);
        }
    }
    getNewAllReports=(value)=> {
        //const orgIds = localStorage.getItem("orgIds")? localStorage.getItem("orgIds").split(",") : null
        this.props.setResorcesSelectedList(null);
        this.props.getResourceList("",value, '');      
    }
    showImg=(value)=>{
        const categories = this.props.categories;
        const categoryId = value.categoryId;
        const postId = value.id;
        const postTitle = value.title;
        const organization = value.organization;
        const catergoryDetails = categories && categories.find(category => category.id == categoryId);
        if(catergoryDetails && catergoryDetails.name) {
            const categoryName = catergoryDetails.name;
            this.props.setPostOpenHistory(categoryName, postId, postTitle, organization)
        } else {
            console.error("Post category name not found");
        }

        if (value.fileURL){
            this.setState({showImg:!this.state.showImg,list:value, iframeLoading: true})
        }
        if(!value.read) {
            //let {categories,resourceList} = this.props.Resources;
            this.props.setReportAsRead(value.categoryId,value.id, value.organization);
        }
        // notices category names list
        const noticeCategoriesName = ["Client Alerts", "General Alerts"];
        console.log("catergoryDetails : ",catergoryDetails);
        if(!value.opened && catergoryDetails && noticeCategoriesName.includes(catergoryDetails.name)) {
            this.props.getPostById({categoryId: value.categoryId, postId: value.id})
        }
    }
    
    handleSlideChange = (index) => {
        const {resourceList} = this.props;
        const post = resourceList[index] || null;
        if(post && !post.read) {
            this.props.setReportAsRead(post.categoryId,post.id, post.organization);
        }
        console.log("slide changed from index : ", index);
    }

    handleChange(e, value) {
        this.setState({ activeIndex: value });
     }

     handleShowImgClose = () => {
        this.setState({showImg:false});
    }
    handleCloseFilter = () => {
        this.setState({showFilter:false})
    }
    handleShowFilter = () => {
        let{categories, keywords}=this.props;
        if(categories || keywords) {
            this.setState({showFilter:true})
        }
    }

    getScrollHeight() {
        let container = document.getElementsByClassName("resources-list-panel")[0];
        let bulletinContent = document.getElementsByClassName("bulletin-posts-list")[0];
        const widgetServices = new WidgetServices()
        if(widgetServices.isWidgetApp()){
            return `calc(100vh - ${this.state.windowWidth > 767 ? 500 : 450}px)`
        } else if (container && bulletinContent) {
            const categoryPanelHeight = container.clientHeight - (bulletinContent.clientHeight + 35);
            return categoryPanelHeight ? `${categoryPanelHeight}px` : '220px';
        } else {
            return '220px'
        }
    }

    downloadPost = (url, description) => {
        const widgetServices = new WidgetServices();
        url = getURLFromText(description) || url;

        if (url) {
            if (widgetServices.isWidgetApp()) {
                const data = { url };
                widgetServices.downloadPostHandler(data)
            } else {
                // window.open(url, "_blank");
                var windowReference = window.open();
                if(windowReference) {
                    windowReference.location = url;
                    openPopup(windowReference)
                }
            }
            
        }
    }

    render() { 
        const settings = {
			dots: false,
			infinite: false,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: false,
			arrows: true,
			rtl: false,
            beforeChange: this.handleSlideChange
		};
        let {newReportAvailable, loading, selectedList, resourceList, categories, resourcePanelOrgFiltered, filterItemsCount } = this.props;
        let {Resources}=this.props;
        return ( 
            <div data-testid="landing-page-my-resources-panel" className={`resources-panel resources-list-panel ${localStorage.getItem("widgetApp") ? "widget-resources-panel" : ""}`} >
               <div className="bulletin-posts-list px-3 pt-3" >
                   {this.state.showFilter && 
                      <ResourcesFilter
                        showFilter={this.state.showFilter}
                        sort={this.state.sort}
                        collection={this.state.collection}
                        Resources={Resources}
                        handleCloseFilter={this.handleCloseFilter}
                        clearFilter={this.clearFilter}
                        applyFilter={this.applyFilter}
                        handleSort={this.handleSort}
                        handleReport={this.handleReport}
                        handleKeywords={this.handleKeywords}
                        panel="resources"
                        /> 
                    }
                {this.state.showImg &&
                    <PostView
                      open={this.state.showImg}
                      post={this.state.list}
                      handleClose={() => this.setState({showImg:false})}
                    />
                }
             
                <LifeSherpaLoading loading={loading}/> 
                { resourceList && resourceList.length > 0 ?
                    <>
                    <div className={`d-flex mt-1 ${selectedList && selectedList.name === 'Filtered' ? "resources-filters-header" : "resources-filters-header-filtered"}`}>
                        <div className="pr-2">
                            <IconButton data-testid="my-resources-filter-icon-button"   title="Filter" className="text-white rounded-circle font-lg ml-1" style={{background:'#008C95'}} onClick={()=>this.handleShowFilter()}>
                                <FilterAltIcon className="filter-icon" fontSize="large"/>                            
                            </IconButton>
                        </div>
                        <div data-testid="selected-category" className="text-secondary text-right font-lg ml-auto mr-1 base-font-color">{selectedList ? selectedList.name + " (" + selectedList.totalCount + ")" : resourceList ? 'All New ('+resourceList.length + ')' : ''}</div>
                    </div>
                    <div  className="  mx-3">
                    <Slider {...settings}>
                        {resourceList && resourceList.map((list,key)=>(
                        <div data-testid={`my-resources-list-item-${key+1}`}  className="list-card-slider mt-3 " style={{borderRadius:16+"px",height: '250px'}}  key={key}>
                            <div data-testid={`my-resources-list-item-thumbnail-${key+1}`} onClick={()=>this.showImg(list)} className="mx-auto my-auto text-center p-3 d-flex align-items-center justify-content-center" style={{width:'180px',height:'175px'}}><img className="img-fluid" style={{maxHeight:'150px'}} src={list.thumbnailURL} alt="" /></div>
                           {list.fileURL ? 
                            <div className="d-flex  w-100 align-items-center justify-content-between font-md  mb-2" style={{margin:"12px"}}>
                                <div className="text-muted  text-left align-items-left font-sm  w-50" >
                                    {list.contentType ?  list.contentType+" " : "Resource File " }
                                </div> 
                                <div className="w-50 align-items-right text-right">
                                    {list.sharingOption && ["Sharable", "Shareable"].includes(list.sharingOption) ?
                                        <a data-testid={`download-button-for-report-${key+1}`}  /*href={list.fileURL}*/ target="_blank" onClick={() => this.downloadPost(list.fileURL, list.description)} download>
                                            <div key={"statuslabel"}
                                            className="download-label ml-auto " 
                                            >
                                            Download
                                            </div>  
                                        </a>
                                        : ""
                                    }
                                </div>  
                            </div>
                            :""}
                            <div title={list.title} className="text-truncate font-md text-secondary">{list.title}</div>
                            <div className="col-12 text-muted font-sm mb-1">{moment(list.createdOn).format('MMM DD, YYYY')}</div>
                            {/* {!list.thumbnailURL&&<div className="col-12 font-sm text-secondary mb-3">{list.description}</div>} */}
                            {list.description && <div className="col-12 font-sm text-muted mb-3"><Linkify text={list.description}/></div>}
                        </div>               
                        ))}
                    </Slider>
                    </div>
                   
                </>
                :  <>
                <div className={`d-flex mt-1 ${selectedList && selectedList.name === 'Filtered' ? "resources-filters-header" : "resources-filters-header-filtered"}`}>
                    <IconButton data-testid="my-resources-filter-icon-button"  title="Filter" className="text-white rounded-circle font-lg ml-1" style={{background:'#008C95'}} onClick={()=>this.handleShowFilter()}>
                        <FilterAltIcon className="filter-icon" fontSize="large"/>                    
                    </IconButton>
                    <div className="text-secondary font-lg ml-auto mr-1 base-font-color">{selectedList ? selectedList.name + " (" + selectedList.totalCount + ")" : resourceList ? 'All New ('+resourceList.length + ')' : ''}</div>
                </div>
                <div  className=" mx-2">
                <Slider {...settings}>
                   
                    <div data-testid={`no-report-data-found`} className="list-card-slider mt-3 mb-4 " style={{borderRadius:16+"px",height: '250px'}}  >
                        <div className="mx-auto my-auto text-center p-3 d-flex align-items-center justify-content-center" style={{width:'200px',height:'175px'}}>
                            <img className="img-fluid" style={{height:'150px'}} src={"my image"} alt="" />
                            </div>
                            <div className="col-12 mb-4 font-md text-center text-secondary">No Report Found</div>
                        <div className="col-12 font-md text-secondary mb-2" style={{fontSize:"12px"}}>{"  "}</div>
                      
                        <div className="col-12 font-md text-secondary">{"   "}</div>
                        <div className="col-12 text-muted font-sm mb-3">{"  "}</div>
                        {/* {!list.thumbnailURL&&<div className="col-12 font-sm text-secondary mb-3">{list.description}</div>} */}
                    </div>               
                  
                </Slider>
                </div>
               
            </>
                }
            </div>
            <h2 className="text-secondary font-lg base-font-color px-3">Collection</h2>    
            <Scrollbars
                className="rct-scroll list-sherpa-activity resources-list-background"
                autoHide
                style={{ height: this.getScrollHeight() }}
            >
                    <Grid
                        container
                        direction="row"
                        justifycontent="flex-start"
                        alignItems="flex-start"
                        className="resources-category-buttons-grid px-3"
                    >
                        <Grid data-testid="my-resources-all-new-button" item >
                            <Button color="secondary" variant="contained" className={selectedList ? "h-30 resource-category-buttons mx-1 my-1" : "h-30 resource-category-buttons-selected mx-1 my-1"} disabled={!newReportAvailable} onClick={() => this.getNewAllReports('new')}>All New</Button>
                        </Grid>
                        {this.state.showFilteredButton &&
                            <Grid data-testid="my-resources-filtered-button" item  >
                                <Button color="secondary" variant="contained" className={selectedList && selectedList.name === 'Filtered' ? "h-30 resource-category-buttons-selected mx-1 my-1" : "h-30 resource-category-buttons mx-1 my-1"} onClick={() => this.applyFilter(resourcePanelOrgFiltered)}>
                                    <Badge className="mx-1 mr-2" badgeContent={filterItemsCount || 0}  /*color="error"*/>Filtered</Badge>
                                </Button>
                            </Grid>
                        }
                        {categories && categories.map((list,key)=>(
                            <Grid item key={key}>
                                <Button color="secondary" variant="contained" data-testid={`my-resources-${key+1}-category-button`} className={selectedList && selectedList.id === list.id ? "h-30 resource-category-buttons-selected mx-1 my-1" : "h-30 resource-category-buttons mx-1 my-1"} key={key} onClick={()=>this.handleList(list.id, list.organization)}>
                                    <Badge className="mx-1 mr-2" badgeContent={list.totalCount} style={{width:"fitContent"}} /*color="error"*/>{list.name.length > 45 ? list.name.substring(0,45)+"..." : list.name }</Badge>  
                                </Button>
                            </Grid> 
                         ))}   
                    </Grid>
            </Scrollbars>
            </div>
            
        );
    }
}
const mapStateToProps = ({Resources,settings, clientsList}) => {
    const { screenThemeClass, popupThemeClass } = settings; 
    const {resourcePanelOrgFiltered} = clientsList 
    const {resourceList, categories, loading, newReportAvailable, selectedList, keywords, resourceFilters, filterItemsCount} = Resources
    return { resourceList, categories, loading, newReportAvailable, selectedList, keywords, resourceFilters ,screenThemeClass, popupThemeClass, Resources, resourcePanelOrgFiltered, filterItemsCount };
}

export default connect(mapStateToProps,{
    getResourceList,
    getcategories,
    getKeywords,
    setReportAsRead,
    setResorcesAndCategories, 
    clearResourcesData, 
    setPostOpenHistory, 
    setResorcesSelectedList,
    getPostById,
    setResourcesFilterItemsCount
})(withStyles(styles, { withTheme: true })(BolletinBoard));
