/**
 * Clients List Actions
 */

import {axiosClient} from '../sherpaApi'
import {showActivityConfirmation} from '../actions'
import { NotificationManager } from 'react-notifications';
import moment from 'moment'
// import 'firebase/auth';
// import firebase from 'firebase/app';
import {auth, database} from '../firebase'
import { ref, onValue, query, orderByChild, equalTo  } from "firebase/database"
// import qs from "qs";  
import {isAchievementCreatedByToday} from "../helpers/helpers" 

import {
    GET_SUCCESS_ALL_ACTIVITIES_LIST,
    SELECT_ACTIVITY_ID,
    GET_RECENT_ACTIVITIES,
    GET_SELECTED_ACTIVITIES,
    UPDATE_ACTIVITIES_SEARCH,
    SEARCH_ACTIVITIES,
    GET_ACTIVITIES_LIST,
    GET_SUCCESS_ACTIVITIES_LIST,
    SET_ACTIVITIES_REMINDER,
    SET_SUCCESS_ACTIVITIES_REMINDER,
    GET_ACTIVITIES_LIST_DETAIL,
    SET_ACTIVITIES_INPROGESS,
    GET_UNCONFIRM_ACHIEVEMENTS,
    SET_ACTIVITIES_RESET,
    SET_SUCCESS_ACTIVITIES_RESET,
    GET_SUCCESS_ACTIVITIES_LIST_DETAIL,
    GET_REALTIME_ACTIVITY_DETAIL,
    GET_SUCCESS_REALTIME_ACTIVITY_DETAIL,
    SHOW_LOADING_INDICATOR_ACTIVITIES_LIST,
    HIDE_LOADING_INDICATOR_ACTIVITIES_LIST,
    GET_ACTIVITY_FILTERS,
    START_ACTIVITY,
    GET_START_ACTIVITY,
    GET_START_ACTIVITY_DETAILS,
    GET_PAUSE_ACTIVITY,
    GET_END_ACTIVITY,
    GET_CANCEL_ACTIVITY,
    GET_RESUME_ACTIVITY,
    GET_RESUME_ACTIVITY_DETAILS,
    GET_LOADING,
    EDITOR_LOADING,
    GET_ENDSTEP,
    STOP_LOADING,
    GET_NETWORK_ACTIVITY,
    GET_NETWORK_ACTIVITY_SUCCESS,
    GET_LAUNCHPAD,
    GET_ACTIVITY_CALENDER,
    GET_LIST_STYLE,
    SET_ACTIVITY_TYPE,
    GET_SUMMERY_ACTIVITY,
    REMOVE_SUMMERY_ACTIVITY,
    ACTIVITY_START_TIME,
    REMOVE_TIMER_ACTIVITY,
    GET_REALTIME_ACTIVITY_DETAIL1,
    GET_COURES,
    REMOVE_COURES,
    GET_PINRESPONSE,
    REMOVE_PINRESPONSE,
    GET_WPQLINK,
    GET_REALTIME_USER_ACTIVITIES,
    CLEAR_START_ACTIVITY_DETAILS,
    GET_LOADING_FOR_COURSE,
    START_STEP_SLIDER_LOADING,
    STOP_STEP_SLIDER_LOADING,
    GET_ACTIVITY_GROUPS_LIST_SUCCESS,
    GET_ACTIVITY_GROUPS_LIST,
    GET_ACTIVITY_GROUPS_LIST_FAILED,
    EXECUTE_ACTIVITY,
    SET_ACTIVITIES_ACHIEVEMENTS_LIST,
    GET_WPQ_LOADING,
    HIDE_WPQ_LOADING,
    HANDLE_SHOW_PHOTO_CAMERA,
    CATCH_API_ERROR,
    SET_FILTERED_ACTIVITIES_DATA,
    HIDE_EXECUTE_ACTIVITY_CONFIRMATION_PANEL,
    SHOW_EXECUTE_ACTIVITY_CONFIRMATION_PANEL,
    ACTIVITY_PREVIEW_LOADING,
    SHOW_ACTIVITY_DETAILS_PANEL,
    HIDE_ACTIVITY_DETAILS_PANEL,
    ADJUST_ACHIEVEMENT_TIMER,
    ADJUST_ACHIEVEMENT_TIMER_SUCCESS,
    ADJUST_ACHIEVEMENT_TIMER_FAILED,
    GET_USERS_ECOUNTER_TYPES_ACTIVITIES,
    GET_USERS_ECOUNTER_TYPES_ACTIVITIES_SUCCESS,
    MAP_USERS_ECOUNTER_TYPES_ACTIVITIES_REALTIME_DATA,
    MAP_USERS_ECOUNTER_TYPES_ACTIVITIES_REALTIME_DATA_SUCCESS,
    HANDLE_ENCOUNTER_ACTIVITY_HISTORY,
    HANDLE_ENCOUNTER_ACTIVITY_DETAILS,
    HANDLE_ENCOUNTER_ACTIVITY_EXECUTION,
    SET_CLIENT_ACTIVITIES_TYPE,
    GET_ECOUNTER_ACTIVITIES_SUMMARY,
    GET_ECOUNTER_ACTIVITIES_SUMMARY_SUCCESS,
    GET_ECOUNTER_ACTIVITIES_SUMMARY_FAILED,
    GET_SUMMARY_LOADING,
    REALTIME_UPDATE_ACTIVITIES,
    RESET_ACTIVITIES_CHAT_CONTACT_GROUP_LIST,
    UNASSIGN_CLIENT_COMMON_ACTIVITY,
    UNASSIGN_CLIENT_COMMON_ACTIVITY_SUCCESS,
    UNASSIGN_ACTIVITY_TO_CLIENT_FAILED,
    HIDE_UNASSIGN_ACTIVITY_LOADING,
    SELECT_ACTIVITY_FILTER_GROUP,
    RESET_ACTIVITIES_LIST,
    UPDATE_ACTIVITEIS_LIST,
    SHOW_EXECUTE_ACTIVITY_PANEL,
    CLEAR_ORGANIZATIONS_FILTER_FROM_ACTIVITIES
} from './types';
import { filter } from 'lodash';
import { object } from 'joi';

/**
 * Redux Action To Emit Boxed Layout
 * @param {*boolean} isBoxLayout 
 *///Realtime/orgId/Groups/groupId/userId/achievementsInProgress
export const getRealtimeActivityDetail = (groupId, userId, activityId) => (dispatch) =>{
    let organization = localStorage.getItem('organization')
    dispatch({type: GET_REALTIME_ACTIVITY_DETAIL})
    const refUrl =  ref(database, `Realtime/${organization}/Groups/${groupId}/${userId}/achievementsInProgress`)
    const orderByChildRef = query(refUrl, orderByChild('routineId'), equalTo(activityId))
    onValue(orderByChildRef, (snapshot) => {
        const realtimeData = snapshot.val();
        let data = realtimeData && Object.keys(realtimeData) ? realtimeData[Object.keys(realtimeData)[0]] : null
        let actualData = data && data.routine && data.routine.steps ? data : null
        dispatch({ type: GET_SUCCESS_REALTIME_ACTIVITY_DETAIL, payload: actualData });
    })
 }
 export const getLoading =()=>(dispatch)=>{
    dispatch({ type: GET_ACTIVITIES_LIST });
 }

 export const getRealtimeCommonActivityDetail = (orgIds, userId, data) => (dispatch) =>{
    dispatch({type: GET_REALTIME_ACTIVITY_DETAIL});
    const defaultOrganization = localStorage.getItem('orgId');
    let promises = []
    for (let index = 0; index < orgIds.length; index++) {
        const orgId = orgIds[index];
        const activitiesList = data ? data.filter(activity => activity.organization === orgId || (!activity.organization && defaultOrganization === orgId)) : [];
        let promise = onValue(query(ref(database, `Realtime/${orgId}/Users/${userId}/achievementsInProgress`), orderByChild('preview'), equalTo(false)), (snapshot) => {
            const realtimeData = snapshot.val();
            console.log("Real time all activities data for logged in user ", {orgId});
            let inProgressList=[]
            realtimeData&&Object.keys(realtimeData).length > 0?Object.keys(realtimeData).map((dateObjKey, key) => {
                realtimeData[dateObjKey].achievementId=dateObjKey
                inProgressList.push(realtimeData[dateObjKey])
                           
             }):"" 
             activitiesList.map((step,index)=>{
                activitiesList[index].state='';
            });
            if(inProgressList) {
                dispatch({ type: SET_ACTIVITIES_ACHIEVEMENTS_LIST,payload:inProgressList });
            }
            activitiesList.map((steps,index)=>{
                let count=0;
                let add={}
                inProgressList.find((li)=>{
                    if(li.routineId===steps.id) {
                        add.count=li.stepsCount
                        add.state=li.state
                        let stepsCompletion = li.StepsCompletion ? li.StepsCompletion : {};
                        let stepsCompletionList = [];
                        if (stepsCompletion) {
                        Object.keys(stepsCompletion).map((stepCompId, key) => {
                            stepsCompletionList.push(stepsCompletion[stepCompId]);
                        });
                        stepsCompletionList = stepsCompletionList.filter(step => step.status == "Done" || step.status == "Skipped" )
                        }
                        add.completed = stepsCompletionList.length;
                        if(add.completed > 0 && stepsCompletionList[add.completed - 1].step) {
                            add.nextStep = stepsCompletionList[add.completed - 1].step.title;
                        }
    
                        add.achievementId=li.achievementId;
                        add.scheduleKey = li.scheduleKey
                        if(li.routine && li.routine.activityGroupId) {
                            add.activityGroupId = li.routine.activityGroupId
                        } 
                        steps = {...steps,...add}
                        activitiesList[index] = steps
                    }               
                })
            }
            )   
            if(activitiesList && activitiesList.length > 0) {
                let consoleList = [];
                activitiesList.map(activity => {
                    consoleList.push({title:activity.title,id:activity.id,state:activity.state,routineViewType:activity.routineViewType, scheduleKey: activity.scheduleKey});
                });
                console.table(consoleList);
            }     
            dispatch(setRealtimeCommoneActivities(activitiesList));
        })
        promises.push(promise)
    }
    return Promise.all(promises)
 }
 
 export const getRealtimeCommonActivityDetailN = ({orgId, groupId, userId,data}) => (dispatch) =>{
    let organization = orgId || localStorage.getItem('organization');
    if(Array.isArray(groupId)) {
        groupId = (groupId[0] && groupId[0].id) || '';
    }
    const auth_uid = localStorage.getItem("auth_uid")
   // dispatch({type: GET_REALTIME_ACTIVITY_DETAIL})
   if(!organization || !groupId || !userId) {
       return;
   }
   dispatch({ type: GET_ACTIVITIES_LIST });
   const url =  `Realtime/${organization}/Groups/${groupId}/${userId}/achievementsInProgress`;
   console.log("user realtime activities path : ", url);
   const refUrl = ref(database, url);
   const topnRef = query(refUrl, orderByChild('preview'), equalTo(false))
   onValue(topnRef, (snapshot) => {
        const realtimeData = snapshot.val();
        console.log("Real time all activities data ", realtimeData);
        let inProgressList=[];
        realtimeData&&Object.keys(realtimeData).length > 0?Object.keys(realtimeData).map((dateObjKey, key) => {
            realtimeData[dateObjKey].achievementId=dateObjKey
            inProgressList.push(realtimeData[dateObjKey])
                       
         }):"" ;
         console.log("realtimeData => ",inProgressList);
         data.map((step,index)=>{
            data[index].state=''
        })
         data.map((steps,index)=>{
            let count=0;
            let add={}
            inProgressList.map((li)=>{
               if(li.routineId===steps.id) {
                add.count=li.stepsCount
                add.state=li.state;
                add.startDate = li.startDate;
                add.routineUserId = li.routineUserId;
                add.showResumeButton = li.routineUserId === auth_uid ? true : false
                if(li.routine) {
                    if(li.routine.steps && Object.keys(li.routine.steps).length > 0) {
                        add.steps = li.routine.steps;
                    }
                    if(li.routine.expectedDuration) {
                        add.expectedDuration = li.routine.expectedDuration
                    }
                }
                
                let stepsCompletion = li.StepsCompletion ? li.StepsCompletion : {};
                let stepsCompletionList = [];
                if (stepsCompletion) {
                  Object.keys(stepsCompletion).map((stepCompId, key) => {
                       stepsCompletionList.push(stepsCompletion[stepCompId]);
                  });
                  stepsCompletionList = stepsCompletionList.filter(step => step.status == "Done" || step.status == "Skipped" )
                }
                add.stepsCompletionList = stepsCompletionList;
                add.completed = stepsCompletionList.length;
                if(add.completed > 0 && stepsCompletionList[add.completed - 1].step) {
                    add.nextStep = stepsCompletionList[add.completed - 1].step.title;
                }
                add.scheduleKey = li.scheduleKey
                add.achievementId=li.achievementId;
                if(li.routine && li.routine.activityGroupId) {
                    add.activityGroupId = li.routine.activityGroupId
                }
                if (li.routine && li.routine.type == "Touchpoint") {
                    const {timestamps = {}, startDate, createdAt} = li;
                    const createdDate = createdAt || timestamps["notStarted,notStarted"] || timestamps["notStarted,delayed"] || startDate;
                    const scheduleNotExpired = isAchievementCreatedByToday(createdDate)
                    add.scheduleNotExpired = scheduleNotExpired
                }
                
                steps={...steps,...add}
                data[index]=steps
            }         
           })
        })  
        console.log("%c Updated user activities list with realtime data for user:"+userId,"color:green"); 
        if(data && data.length > 0) {
            let consoleList = [];
            data.map(activity => {
                consoleList.push({title:activity.title,id:activity.id,state:activity.state,routineViewType:activity.routineViewType});
            });
            console.table(consoleList);
        }     
        dispatch({ type: GET_REALTIME_USER_ACTIVITIES,payload:data });
        dispatch({type:REALTIME_UPDATE_ACTIVITIES, payload: Math.random()}); 
        dispatch({type: HIDE_LOADING_INDICATOR_ACTIVITIES_LIST})   
    })
 }

 export const setActivitiesAchievementsList = (list) => (dispatch) =>{
    dispatch({ type: SET_ACTIVITIES_ACHIEVEMENTS_LIST,payload:list });
 }
 export const getActivitiesListN = ({userId, orgId, groupId, filter, activityType}) => (dispatch) => {
    let idToken = localStorage.getItem('idToken')
    let activityGroupId=filter?filter:'';
    console.log(" Fetching user activities list for userId:"+userId+"......");
    let url = ""
    if (activityType){
        url = `/users/${userId}/activities?activityGroupId=${activityGroupId}&type=${activityType}` //`/users​/${memberUerId}​/activities`
    } else {
        url = `/users/${userId}/activities?activityGroupId=${activityGroupId}&includeQuickTasks=true`
    }
    //clear filter activities and starting spinner
    dispatch({ type: SET_FILTERED_ACTIVITIES_DATA, payload: null });
    dispatch({ type: GET_ACTIVITIES_LIST });

    if (userId) {
        axiosClient.get(url, {headers: {
            'Authorization': `Bearer ${idToken}`
         }
        })
        .then((response) => {
            console.log("activities api response ----.",response.data);
            console.log("%c Fetched user activities list for user:"+userId,"color:green");
            dispatch({ type: GET_REALTIME_USER_ACTIVITIES, payload: response.data });
            dispatch(getRealtimeCommonActivityDetailN({ orgId, groupId, userId, data:response.data}))
           
        }).catch(error => {
            const errorMsg = error.response ? error.response.data.message : error.message;
            console.log("%c Error Occured while fetching user activities list for user:"+userId,"color:red", errorMsg); 
            dispatch({type: HIDE_LOADING_INDICATOR_ACTIVITIES_LIST})
            dispatch({ type:GET_REALTIME_USER_ACTIVITIES, payload: [] });
         })
    } else {
        console.log("%c Error Occured while Fetching user activities list","color:red");
        dispatch({type: HIDE_LOADING_INDICATOR_ACTIVITIES_LIST})
        dispatch({ type:GET_REALTIME_USER_ACTIVITIES, payload: [] });
    }   
}
export const getActivityGroupsList = (userId, orgIds, type) => (dispatch) => {
    const orgId = localStorage.getItem('orgId')	
    const idToken = localStorage.getItem('idToken');
    let url = ""
    if (orgIds && orgIds.length > 0) {
        url = `/users/${userId}/activities/groups?type=${type}&organizations=${encodeURIComponent(JSON.stringify(orgIds))}`
    } else {
        url = `/users/${userId}/activities/groups?type=${type}`
    }
    dispatch({ type: GET_ACTIVITY_GROUPS_LIST });
    axiosClient.get(url, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
        }).then((response) => {
            let activityData = response.data || [];
            activityData.map(ele => {
                if (ele.name === "Private Activities") {
                    ele.orgId = orgId 
                } else {
                    ele.orgId = ele.organization 
                }
            })
            dispatch({ type: GET_ACTIVITY_GROUPS_LIST_SUCCESS, payload: response.data });
           
        }).catch(error => {
            // error handling
            console.log("error occured ", error);
            dispatch({type: GET_ACTIVITY_GROUPS_LIST_FAILED})
        })
}

 
export const getSelectedActivity = (activity) => ({
    type: GET_SELECTED_ACTIVITIES,
    payload: activity
});
export const getUnconfirmAchivForm = (userId) => (dispatch) => {
    
}
export const getRecentActivities = (activities) => ({
    type: GET_RECENT_ACTIVITIES,
    payload: activities
});
export const setInprogressActivitiesList = (activities) => ({
    type: SET_ACTIVITIES_INPROGESS,
    payload: activities
});
export const setActivityReminder = (userId, activityId, orgId, workflow) => (dispatch) => {
    let idToken = localStorage.getItem('idToken')
    let url = `/users/${userId}/notify/routine-start?organization=${orgId}` ///users/{recipientId}/notify/routine-start
    const body = {}
    if (workflow) {
        body['achievementId'] = activityId
    } else {
        body['activityId'] = activityId
    }
     
    dispatch({ type: SET_ACTIVITIES_REMINDER });
    axiosClient.post(url, body, { headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        if(response.data && response.data.message) {
            NotificationManager.success(response.data.message);
        }
        dispatch({ type: SET_SUCCESS_ACTIVITIES_REMINDER, payload: response.data });
    }).catch((error) => {
        dispatch({ type: HIDE_LOADING_INDICATOR_ACTIVITIES_LIST });
        console.log("error occured ", error);
    })
}

export const notifyWorkflowGroupRoleAssignmentActivity = (groupId, groupRolePendingTaskId, orgId) => (dispatch) => {
    let idToken = localStorage.getItem('idToken')
    let url = `/workflow/groups/${groupId}/notify/role-assignment?organization=${orgId}`;
    const body = {groupRolePendingTaskId};
     
    dispatch({ type: SET_ACTIVITIES_REMINDER });
    axiosClient.post(url, body, { headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        if(response.data && response.data.message) {
            NotificationManager.success(response.data.message);
        }
        dispatch({ type: SET_SUCCESS_ACTIVITIES_REMINDER, payload: response.data });
    }).catch((error) => {
        dispatch({ type: HIDE_LOADING_INDICATOR_ACTIVITIES_LIST });
        console.log("error occured ", error);
    })
}

export const setActivityReset = (userId, activity) => (dispatch) => {
    const orgId = (activity && activity.organization) || localStorage.getItem('selectedOrgId');
    let idToken = localStorage.getItem('idToken');
    let url = `/users/${userId}/achievements/${activity.achievementId}/cancel?organization=${orgId}` ///users/{userId}/achievements/{achievementId}/cancel
    let params = {
        'timestamp': moment().format("YYYY-MM-DD HH:mm:ss Z")
    }
    dispatch({ type: SET_ACTIVITIES_RESET });
    axiosClient.post(url, null, { params: params, headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        dispatch({ type: SET_SUCCESS_ACTIVITIES_RESET, payload: response.data });
    }).catch((error) => {
        dispatch({ type: HIDE_LOADING_INDICATOR_ACTIVITIES_LIST });
        console.log("error occured ", error);
    })
}
export const getActivitiesList = (memberId,filter) => (dispatch) => {
    const orgIds = (localStorage.getItem("orgIds") && localStorage.getItem("orgIds").split(",")) || [localStorage.getItem("orgId")]
    const idToken = localStorage.getItem('idToken')
    const activityGroupId = filter ? filter : ''
    let url = `/users/${memberId}/activities?activityGroupId=${activityGroupId}&includeQuickTasks=true` //`/users​/${memberUerId}​/activities`
    // let queryParam = {activityGroupId: groupId, }
    console.log("orgIds :", orgIds);
    console.log(" Fetching user activities list for userId:"+memberId+"......");
    // dispatch({type: CLEAR_ORGANIZATIONS_FILTER_FROM_ACTIVITIES});
    dispatch({ type: SET_FILTERED_ACTIVITIES_DATA, payload: null });
    dispatch({ type: GET_ACTIVITIES_LIST });
    if (memberId) {
        axiosClient.get(url, {headers: {
            'Authorization': `Bearer ${idToken}`
         }
        }).then((response) => {
            console.log("activities api response ----.",response.data);
            console.log("%c Fetched user activities list for user:"+memberId,"color:green");
            dispatch({ type: RESET_ACTIVITIES_LIST});
            dispatch({ type: GET_SUCCESS_ACTIVITIES_LIST, payload: response.data });
            dispatch({ type: SET_FILTERED_ACTIVITIES_DATA, payload: null });
            dispatch(getRealtimeCommonActivityDetail(orgIds, memberId,response.data))
        }).catch((error) => {
            dispatch({ type: HIDE_LOADING_INDICATOR_ACTIVITIES_LIST });
            const errorMsg = error.response ? error.response.data.message : error.message;
            console.log("%c Error Occured while fetching user activities list for user:"+memberId,"color:red", errorMsg);
            // console.log("error occured ", error);
        })
    } else {
       // dispatch({ type: GET_ACTIVITIES_LIST, payload: null });
       dispatch({ type: HIDE_LOADING_INDICATOR_ACTIVITIES_LIST });
    }
    
}

export const getactivitiesCalendar = (uid) => (dispatch) => {
    console.log("userId ->", uid);
    let idToken = localStorage.getItem('idToken')
    const orgId = localStorage.getItem("selectedOrgId")
    dispatch({type:GET_SUMMARY_LOADING})
    let url =`/users/${uid}/activities/calendar` //users/{userId}/activities/calendar
    axiosClient.get(url,{headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        //dispatch(getSummeryList(response.data))
        let expiredActivity=0;
        let startedActivity=0
        response.data.scheduled.map((scheduled)=>{
            if(scheduled.status==='expired') expiredActivity++
            if(scheduled.status==='notStarted') startedActivity++
        })
        let data={expiredActivity:expiredActivity,startedActivity:startedActivity}
        dispatch({type:GET_SUMMERY_ACTIVITY,payload:response.data})
        dispatch({type:GET_ACTIVITY_CALENDER,payload:data})
        
    }).catch((error) => {
        // error handling
        console.log("error occured ", error);
        dispatch({type:GET_SUMMERY_ACTIVITY,payload:null})
        dispatch({type: CATCH_API_ERROR, payload: error})  
    })
}
export const handleListStyle= (value) => (dispatch) => {
    if(value === "summary") {
        let auth_uid = localStorage.getItem('auth_uid');
        dispatch(getactivitiesCalendar(auth_uid))
    }
    dispatch({type:GET_LIST_STYLE,payload:value})
}

export const UpdateSummeryList=(data)=> (dispatch) => {
    dispatch({type:GET_ACTIVITIES_LIST})
    dispatch({type:GET_SUMMERY_ACTIVITY,payload:data})
}
 
export const getFilteredActivitiesList = (memberId, filter, activityType, organizations) => (dispatch) => {
    const idToken = localStorage.getItem('idToken')
    const orgIds = organizations
    const activityGroupId = filter || []
    let url = "" //`/users/${memberId}/activities?activityGroupId=${activityGroupId}` //`/users​/${memberUerId}​/activities`
    if (activityType) {
        url = `/users/${memberId}/activities?activityGroups=${encodeURIComponent(JSON.stringify(activityGroupId))}&type=${activityType}`
    } else {
        url = `/users/${memberId}/activities?activityGroups=${encodeURIComponent(JSON.stringify(activityGroupId))}`
    }

    if (orgIds && orgIds.length > 0) {
        url = url + `&organizations=${encodeURIComponent(JSON.stringify(orgIds))}`
    }

    console.log(" Fetching user activities list for userId:"+memberId+"......");
    dispatch({ type: GET_ACTIVITIES_LIST });
    if (memberId) {
        axiosClient.get(url, {headers: {
            'Authorization': `Bearer ${idToken}`
         }
        }).then((response) => {
            console.log("fetched filtered activities response ----.",response.data);
            dispatch({ type: SET_FILTERED_ACTIVITIES_DATA, payload: response.data });
        }).catch((error) => {
            dispatch({ type: HIDE_LOADING_INDICATOR_ACTIVITIES_LIST });
            const errorMsg = error.response ? error.response.data.message : error.message;
            console.log("%c Error Occured while fetching filter activities list for user:"+memberId,"color:red", errorMsg);
            // console.log("error occured ", error);
        })
    } else {
       // dispatch({ type: GET_ACTIVITIES_LIST, payload: null });
    } 
}
export const removeFilteredActivitiesList = () => (dispatch) => {
        dispatch({ type: SET_FILTERED_ACTIVITIES_DATA, payload: null });
}

export const getRealtimeClienActivity=(data)=>(dispatch)=>{
    dispatch({ type: GET_ACTIVITIES_LIST });
    dispatch({ type: GET_SUCCESS_ACTIVITIES_LIST, payload: data });
}

export const getActivityFilter=()=>(dispatch)=>{
    let idToken = localStorage.getItem('idToken')
    let uid = localStorage.getItem('uid')
    let url = `/users/${uid}/activities/groups` //users/{userId}/activities/groups
    axiosClient.get(url, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        dispatch({ type: GET_ACTIVITY_FILTERS,payload: response.data});
    }).catch((error) => {
        dispatch({ type: HIDE_LOADING_INDICATOR_ACTIVITIES_LIST });
        console.log("error occured ", error);
    })
}

/**
 * Redux Action To Update Client Search
 */
export const updateActivitiesSearch = (value) => ({
    type: UPDATE_ACTIVITIES_SEARCH,
    payload: value
});

/**
 * export const to search Clients
 */
export const onSearchActivities = (value) => ({
    type: SEARCH_ACTIVITIES,
    payload: value
});

/**
 * Redux Action To Update Client Search
 */
export const showActivitiesListLoading = () => ({
    type: SHOW_LOADING_INDICATOR_ACTIVITIES_LIST,
});

/**
 * export const to search Clients
 */
export const hideActivitiesListLoading = () => ({
    type: HIDE_LOADING_INDICATOR_ACTIVITIES_LIST,
});

export const StartActivity = (activityId,timestamp,uid, orgId) => (dispatch) => {
    let idToken = localStorage.getItem('idToken')
    //let uid = localStorage.getItem('uid')
    let date=encodeURIComponent(timestamp)
    console.log(" Starting activity preview for activityId:"+activityId+"....");
    dispatch({type:ACTIVITY_PREVIEW_LOADING})
    let url =`/users/${uid}/achievements/start?activityId=${activityId}&timestamp=${date}&preview=true` //users/{userId}/achievements/start
    let selectedOrgId = localStorage.getItem('selectedOrgId') || localStorage.getItem("orgId");
    if(selectedOrgId || orgId) {
        url = `${url}&organization=${selectedOrgId || orgId}`;
    }
    axiosClient.post(url,null, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        console.log("%c Activity preview started for activityId:"+activityId,"color:green");
        let stepslist=[]
        let activity={}
        let stepsId=[]
        let stepCount=[]
        if(response.data&&response.data.achievement){
            activity=response.data.achievement.routine
            activity.steps && Object.keys(activity.steps).length > 0?Object.keys(activity.steps).map((dateObjKey, key) => {
                stepslist.push(activity.steps[dateObjKey])
                stepsId.push(dateObjKey)
                stepCount.push(key+1)
            }):""
        }
        let date={stepslist:stepslist,activity:activity,stepsId:stepsId,stepCount:stepCount}
        dispatch({ type: GET_START_ACTIVITY,payload: response.data});
        dispatch({ type: GET_START_ACTIVITY_DETAILS,payload: date});
    }).catch((error) => {
        dispatch({ type: STOP_LOADING});
        const errorMsg = error.response ? error.response.data.message : error.message;
        console.log("%c Error Occured while starting activity preview","color:red",errorMsg);
        dispatch({type: CATCH_API_ERROR, payload: error})
    })
}

export const executeActivity = (activityId, timestamp, uid, scheduleKey) => (dispatch) => {
    let idToken = localStorage.getItem('idToken');
    let url = ""
    //let uid = localStorage.getItem('uid')
    let date=encodeURIComponent(timestamp)
    console.log(" Executing activity preview for activityId:"+activityId+"....");
    dispatch({type:EXECUTE_ACTIVITY})
    // dispatch({type:GET_LOADING})
    url = scheduleKey ? `/users/${uid}/achievements/start?activityId=${activityId}&timestamp=${date}&scheduleKey=${scheduleKey}` :  `/users/${uid}/achievements/start?activityId=${activityId}&timestamp=${date}` //users/{userId}/achievements/start
    let selectedOrgId = localStorage.getItem('selectedOrgId') || localStorage.getItem("orgId");
    if(selectedOrgId) {
        url = `${url}&organization=${selectedOrgId}`;
    }
    axiosClient.post(url,null, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        console.log("%c Activity Executing for activityId:"+activityId,"color:green");
        let stepslist=[]
        let activity={}
        let stepsId=[]
        let stepCount=[]
        if(response.data&&response.data.achievement){
            activity = response.data.achievement.routine
            activity.steps && Object.keys(activity.steps).length > 0 && Object.keys(activity.steps).map((dateObjKey, key) => {
                stepslist.push(activity.steps[dateObjKey])
                stepsId.push(dateObjKey)
                stepCount.push(key+1)
            })
            const activityMessage = response.data.message
            const achievementId = response.data.achievementId
            const selfEvaluationFormId = activity && activity.selfEvaluationFormId ? activity.selfEvaluationFormId : null
            const stepCompletionList = response.data.achievement.StepsCompletion && Object.keys(response.data.achievement.StepsCompletion).length > 0 ? Object.keys(response.data.achievement.StepsCompletion).filter(stepCompId => response.data.achievement.StepsCompletion[stepCompId].status === 'Done' || response.data.achievement.StepsCompletion[stepCompId].status == "Skipped") : []
            if ((stepslist.length == stepCompletionList.length || stepslist.length === 0) && (activityMessage == "Achievement resumed successfully") && !selfEvaluationFormId) {
                dispatch(EndActivity(achievementId, timestamp, uid, null, null, selectedOrgId))
            }

        }
        let date={stepslist:stepslist,activity:activity,stepsId:stepsId,stepCount:stepCount}
        dispatch({ type: GET_START_ACTIVITY,payload: response.data});
        dispatch({ type: GET_START_ACTIVITY_DETAILS,payload: date});
    }).catch((error) => {
        dispatch({ type: STOP_LOADING});
        const errorMsg = error.response ? error.response.data.message : error.message;
        console.log("%c Error Occured while Executing activity","color:red",errorMsg);
        dispatch({type: CATCH_API_ERROR, payload: error})
    })
}
export const executeActivityWithAchievementId = (achievementId,timestamp,uid) => (dispatch) => {
    let idToken = localStorage.getItem('idToken')
    //let uid = localStorage.getItem('uid')
    let date=encodeURIComponent(timestamp)
    console.log(" Executing activity preview for achievementId:"+achievementId+"....");
    dispatch({type:EXECUTE_ACTIVITY})
    // dispatch({type:GET_LOADING})
    let url =`/users/${uid}/achievements/${achievementId}/start?timestamp=${date}` //users/{userId}/achievements/start
    let selectedOrgId = localStorage.getItem('selectedOrgId') || localStorage.getItem("orgId");
    if(selectedOrgId) {
        url = `${url}&organization=${selectedOrgId}`;
    }
    axiosClient.post(url,null, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        console.log("%c Activity Executing for achievementId:"+achievementId,"color:green");
        let stepslist=[]
        let activity={}
        let stepsId=[]
        let stepCount=[]
        if(response.data&&response.data.achievement){
            activity=response.data.achievement.routine
            activity.steps && Object.keys(activity.steps).length > 0 && Object.keys(activity.steps).map((dateObjKey, key) => {
                stepslist.push(activity.steps[dateObjKey])
                stepsId.push(dateObjKey)
                stepCount.push(key+1)
            })
            const activityMessage = response.data.message
            const selfEvaluationFormId = activity && activity.selfEvaluationFormId ? activity.selfEvaluationFormId : null
            const stepCompletionList = response.data.achievement.StepsCompletion && Object.keys(response.data.achievement.StepsCompletion).length > 0 ? Object.keys(response.data.achievement.StepsCompletion).filter(stepCompId => response.data.achievement.StepsCompletion[stepCompId].status === 'Done' || response.data.achievement.StepsCompletion[stepCompId].status == "Skipped") : []
            if ((stepslist.length == stepCompletionList.length || stepslist.length === 0) && (activityMessage == "Achievement resumed successfully") && !selfEvaluationFormId) {
                dispatch(EndActivity(achievementId, timestamp, uid, null, null, selectedOrgId))
            }
        }
        let date={stepslist:stepslist,activity:activity,stepsId:stepsId,stepCount:stepCount}
        dispatch({ type: GET_START_ACTIVITY,payload: response.data});
        dispatch({ type: GET_START_ACTIVITY_DETAILS,payload: date});
    }).catch((error) => {
        dispatch({ type: STOP_LOADING});
        const errorMsg = error.response ? error.response.data.message : error.message;
        console.log("%c Error Occured while Executing activity","color:red",errorMsg);
        dispatch({type: CATCH_API_ERROR, payload: error})
    })
}
export const executeActivityForConfigurator = (activityId, timestamp, uid, orgId) => (dispatch) => {
    let idToken = localStorage.getItem('idToken')
    //let uid = localStorage.getItem('uid')
    let date=encodeURIComponent(timestamp)
    const queryParam = `userId=${uid}&organization=${orgId}`
    console.log(" Executing activity preview for activityId:"+activityId+"....");
    dispatch({type:EXECUTE_ACTIVITY})
    // dispatch({type:GET_LOADING})
    let url =`/users/${uid}/achievements/start?activityId=${activityId}&timestamp=${date}&${queryParam}` //users/{userId}/achievements/start
    axiosClient.post(url,null, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        console.log("%c Activity Executing for activityId:"+activityId,"color:green");
        let stepslist=[]
        let activity={}
        let stepsId=[]
        let stepCount=[]
        if(response.data&&response.data.achievement){
            activity=response.data.achievement.routine
            activity.steps && Object.keys(activity.steps).length > 0?Object.keys(activity.steps).map((dateObjKey, key) => {
                stepslist.push(activity.steps[dateObjKey])
                stepsId.push(dateObjKey)
                stepCount.push(key+1)
            }):""
        }
        let date={stepslist:stepslist,activity:activity,stepsId:stepsId,stepCount:stepCount}
        dispatch({ type: GET_START_ACTIVITY,payload: response.data});
        dispatch({ type: GET_START_ACTIVITY_DETAILS,payload: date});
    }).catch((error) => {
        dispatch({ type: STOP_LOADING});
        const errorMsg = error.response ? error.response.data.message : error.message;
        console.log("%c Error Occured while Executing activity","color:red",errorMsg);
        dispatch({type: CATCH_API_ERROR, payload: error})
    })
}

export const StartActivityPreviewConfigurator = (activityId,timestamp,uid, orgId) => (dispatch) => {
    let idToken = localStorage.getItem('idToken')
    //let uid = localStorage.getItem('uid')
    let date=encodeURIComponent(timestamp)
    const queryParam = `&userId=${uid}&organization=${orgId}&previewSource=configurator`
    dispatch({type:ACTIVITY_PREVIEW_LOADING})
    let url =`/users/${uid}/achievements/start?activityId=${activityId}&timestamp=${date}&preview=true${queryParam}` //users/{userId}/achievements/start
    axiosClient.post(url,null, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        let stepslist=[]
        let activity={}
        let stepsId=[]
        let stepCount=[]
        if(response.data&&response.data.achievement){
            activity=response.data.achievement.routine
            activity.steps && Object.keys(activity.steps).length > 0?Object.keys(activity.steps).map((dateObjKey, key) => {
                stepslist.push(activity.steps[dateObjKey])
                stepsId.push(dateObjKey)
                stepCount.push(key+1)
            }):""
        }
        let date={stepslist:stepslist,activity:activity,stepsId:stepsId,stepCount:stepCount}
        dispatch({ type: GET_START_ACTIVITY,payload: response.data});
        dispatch({ type: GET_START_ACTIVITY_DETAILS,payload: date});
    }).catch((error) => {
        // error handling
        console.log("error occured fetching start activity details", error);
        const errorData = error.response ? error.response.data : error
        dispatch({ type: STOP_LOADING});
        dispatch({ type: GET_START_ACTIVITY,payload: errorData});
        dispatch({ type: GET_START_ACTIVITY_DETAILS, payload: errorData});
        dispatch({type: CATCH_API_ERROR, payload: error})
    })
}

export const pauseAcivity = (achievementId,timestamp,uid,routineId,timer) => (dispatch) => {
    let idToken = localStorage.getItem('idToken')
    //let uid = localStorage.getItem('uid')
    let date=encodeURIComponent(timestamp)
    dispatch({type:EDITOR_LOADING})
    let url =`/users/${uid}/achievements/${achievementId}/pause?timestamp=${date}` //users/{userId}/achievements/{achievementId}/pause
    let selectedOrgId = localStorage.getItem('selectedOrgId');
    if(selectedOrgId) {
        url = `${url}&organization=${selectedOrgId}`;
    }
    axiosClient.post(url,null, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        let doneStep=[]
        response.data.achievement.StepsCompletion&&Object.keys(response.data.achievement.StepsCompletion).map((obj, key) => {
            doneStep.push(response.data.achievement.StepsCompletion[obj])
            })	
        dispatch({ type: GET_PAUSE_ACTIVITY,payload: response.data});
        let timerData={routineId:routineId,timer:timer}            
        if(timer) dispatch({type:ACTIVITY_START_TIME,payload:timerData})
    }).catch((error) => {
         // error handling
         console.log("error occured fetching start activity details", error);
         dispatch({ type: STOP_LOADING});
         dispatch({type: CATCH_API_ERROR, payload: error})
    })
}

export const pauseAcivityForConfigurator = (achievementId,timestamp,uid,routineId,timer, executeActivity) => (dispatch) => {
    let idToken = localStorage.getItem('idToken');
    let orgId = localStorage.getItem('porgId');
    //let uid = localStorage.getItem('uid')
    let date=encodeURIComponent(timestamp)
    dispatch({type:EDITOR_LOADING})
    let url =`/users/${uid}/achievements/${achievementId}/pause?timestamp=${date}&organization=${orgId}` //users/{userId}/achievements/{achievementId}/pause
    axiosClient.post(url,null, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        let doneStep=[]
        response.data.achievement.StepsCompletion&&Object.keys(response.data.achievement.StepsCompletion).map((obj, key) => {
            doneStep.push(response.data.achievement.StepsCompletion[obj])
            })	
        dispatch({ type: GET_PAUSE_ACTIVITY,payload: response.data});
        let timerData={routineId:routineId,timer:timer}            
        if(timer) {
            dispatch({type:ACTIVITY_START_TIME,payload:timerData});
        }
        if(executeActivity) {
            window.location.href = "/success";
        }
    }).catch((error) => {
         // error handling
         console.log("error occured fetching start activity details", error);
         dispatch({ type: STOP_LOADING});
         dispatch({type: CATCH_API_ERROR, payload: error})
    })
}
 export const ResumeActivity = (achievementId,timestamp,uid) => (dispatch) => {
    let idToken = localStorage.getItem('idToken')
    //let uid = localStorage.getItem('uid')
    let date=encodeURIComponent(timestamp)
    dispatch({type:EXECUTE_ACTIVITY})
    // dispatch({type:GET_LOADING})
    let url =`/users/${uid}/achievements/start?timestamp=${date}` //users/{userId}/achievements/{achievementId}/start
    let selectedOrgId = localStorage.getItem('selectedOrgId')
    if(selectedOrgId) {
        url = `${url}&organization=${selectedOrgId}`;
    }
    axiosClient.post(url,null, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        let date={}	
        let stepslist=[]
        let activity={}
        let stepsId=[]
        let stepCount=[]
        if(response.data&&response.data.achievement){
            activity=response.data.achievement.routine
            activity.steps && Object.keys(activity.steps).length > 0?Object.keys(activity.steps).map((dateObjKey, key) => {
                stepslist.push(activity.steps[dateObjKey])
                stepsId.push(dateObjKey)
                stepCount.push(key+1)
            }):""
        }     
        date={stepslist:stepslist,activity:activity,stepsId:stepsId,stepCount:stepCount}      
        if(response.data&&response.data.achievement&&response.data.achievement.StepsCompletion){
            let StepsCompletion=response.data.achievement.StepsCompletion
            let list=[]
            let stepCount1=[]
            Object.keys(StepsCompletion).length > 0?Object.keys(StepsCompletion).map((dateObjKey, key) => {          
                list.push(StepsCompletion[dateObjKey].step)
                            
            }):""
            let data=stepslist.filter((steps,index)=>{
                let count=0;
                list.find((li)=>{
                    if(li.title===steps.title) {
                    count=1}
                    
                })
                if(count===0) {
                stepCount1.push(index+1)   
                return steps}
            }
            )
            let ids=stepsId.filter((steps)=>{
                let count=0;
                list.find((li)=>{
                    if(li.id===steps) {   
                    count=1}
                    
                })
                if(count===0) return steps
            }
            )
            date={stepslist:data?data:stepslist,activity:activity,stepsId:ids?ids:stepsId,stepCount:stepCount1}
        }
        dispatch({ type: GET_RESUME_ACTIVITY,payload: response.data});
        dispatch({ type: GET_RESUME_ACTIVITY_DETAILS,payload: date});
            //dispatch({ type: GET_RESUME_ACTIVITY,payload: response.data});
    }).catch((error) => {
        // error handling
        console.log("error occured fetching start activity details", error);
        dispatch({ type: STOP_LOADING});
        dispatch({type: CATCH_API_ERROR, payload: error})
    })
}
export const StartStep = (achievementId,timestamp,stepId,uid, orgId) => (dispatch) => {
    let idToken = localStorage.getItem('idToken')
    //let uid = localStorage.getItem('uid'
    let date=encodeURIComponent(timestamp)
    dispatch({type:START_STEP_SLIDER_LOADING})
    dispatch({type:EDITOR_LOADING})
    let url =`/users/${uid}/achievements/${achievementId}/steps/${stepId}/start?timestamp=${date}&organization=${orgId}` //users/{userId}/achievements/{achievementId}/steps/{stepId}/start
    axiosClient.post(url,null, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        dispatch({ type: STOP_LOADING});
        dispatch({ type: GET_START_ACTIVITY,payload: response.data});
        dispatch({type:STOP_STEP_SLIDER_LOADING})
    }).catch((error) => {
       // error handling
       console.log("error occured ", error);
       dispatch({ type: STOP_LOADING});
       dispatch({type:STOP_STEP_SLIDER_LOADING})
       dispatch({type: CATCH_API_ERROR, payload: error})
    })
}

export const SkipStep = (achievementId,timestamp,stepId,uid, endActivity, orgId) => (dispatch) => {
    let idToken = localStorage.getItem('idToken')
    //let uid = localStorage.getItem('uid')
    let date=encodeURIComponent(timestamp)
    dispatch({type:START_STEP_SLIDER_LOADING})
    dispatch({type:EDITOR_LOADING})
    console.log(" Step skipping......");
    let url =`/users/${uid}/achievements/${achievementId}/steps/${stepId}/skip?timestamp=${date}&organization=${orgId}` //users/{userId}/achievements/{achievementId}/steps/{stepId}/skip
    axiosClient.post(url,null, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        // console.log("skip activity data=>",response.data)
        console.log("%c Step skipped for stepId:"+stepId,"color:green");
        dispatch({ type: STOP_LOADING});
        dispatch({ type: GET_START_ACTIVITY,payload: response.data});
        dispatch({type:STOP_STEP_SLIDER_LOADING});
        if(endActivity) {
            dispatch(EndActivity(achievementId, timestamp, uid, null, null, orgId))
        }
    }).catch((error) => {
        // error handling
        const errorMsg = error.response ? error.response.data.message : error.message;
        console.log("%c Error Occured while skipping step","color:red",errorMsg);
        dispatch({ type: STOP_LOADING});
        dispatch({type:STOP_STEP_SLIDER_LOADING})
        dispatch({type: CATCH_API_ERROR, payload: error})
    })
}
 export const EndStep = (achievementId,timestamp,stepId,uid, stepResponseData, endActivity, orgId) => (dispatch) => {
    let idToken = localStorage.getItem('idToken')
    //let uid = localStorage.getItem('uid')
    let date=encodeURIComponent(timestamp) 
    dispatch({type:START_STEP_SLIDER_LOADING})
    dispatch({type:EDITOR_LOADING})
    let stepPayloadData = null;
    if(stepResponseData) {
        stepPayloadData = stepResponseData;
    }
    console.log(" Step ending......");
    let url =`/users/${uid}/achievements/${achievementId}/steps/${stepId}/end?timestamp=${date}&organization=${orgId}` //users/{userId}/achievements/{achievementId}/steps/{stepId}/end
   
    axiosClient.post(url, stepPayloadData, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        console.log("%c Step ended for stepId:"+stepId,"color:green");
        dispatch({ type: GET_START_ACTIVITY,payload: response.data});
        dispatch({ type: GET_ENDSTEP});
        dispatch({type:STOP_STEP_SLIDER_LOADING});
        if(endActivity) {
            dispatch(EndActivity(achievementId, timestamp, uid, null, null, orgId))
        }
    }).catch((error) => {
        // error handling
        const errorMsg = error.response ? error.response.data.message : error.message;
        console.log("%c Error Occured while ending step","color:red",errorMsg);
        dispatch({ type: STOP_LOADING});
        dispatch({type:STOP_STEP_SLIDER_LOADING})
        dispatch({type: CATCH_API_ERROR, payload: error})
    })
}
export const EndActivity = (achievementId,timestamp,uid,endActivityResponseData,timer, orgId) => (dispatch) => {
    let idToken = localStorage.getItem('idToken')
    //let uid = localStorage.getItem('uid')
    let date=encodeURIComponent(timestamp)
    dispatch({type:EDITOR_LOADING});
    let evaluationFormResponse = null;
    if(endActivityResponseData) {
        evaluationFormResponse = endActivityResponseData;
    }
    console.log(" Activity preview ending......");
    let url =`/users/${uid}/achievements/${achievementId}/end?timestamp=${date}&organization=${orgId}` //users/{userId}/achievements/{achievementId}/end
    axiosClient.post(url, evaluationFormResponse, { 
            headers: {
                'Authorization': `Bearer ${idToken}`
            }
        }
    ).then((response) => {
        console.log("%c Activity preview ended for activityId:"+achievementId,"color:green");
        dispatch({ type: GET_END_ACTIVITY,payload:response.data});
        //dispatch({type:REMOVE_TIMER_ACTIVITY,payload:routineId})
    }).catch((error) => {
        // error handling
        const errorMsg = error.response ? error.response.data.message : error.message;
        console.log("%c Error Occured while ending activity preview","color:red",errorMsg);
        dispatch({ type: STOP_LOADING}); 
        dispatch({type: CATCH_API_ERROR, payload: error})      
    })
}

 export const cancelActivity = (achievementId,timestamp,uid, orgId) => (dispatch) => {
    let idToken = localStorage.getItem('idToken')
    //let uid = localStorage.getItem('uid')
    let date=encodeURIComponent(timestamp)
    dispatch({type:EDITOR_LOADING})
    let url =`/users/${uid}/achievements/${achievementId}/cancel?timestamp=${date}` //users/{userId}/achievements/{achievementId}/cancel
    let selectedOrgId = localStorage.getItem('selectedOrgId');
    if(selectedOrgId || orgId) {
        url = `${url}&organization=${selectedOrgId || orgId}`;
    }
    axiosClient.post(url,null, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        dispatch({ type: GET_CANCEL_ACTIVITY,payload:response.data});
        //dispatch({type:REMOVE_TIMER_ACTIVITY,payload:routineId})
    }).catch((error) => {
         // error handling
         console.log("error occured ", error);
         dispatch({ type: STOP_LOADING});  
         dispatch({type: CATCH_API_ERROR, payload: error})   
    })
}
export const cancelActivityForConfigurator = (achievementId,timestamp,uid, routineId, timerTime, executeActivity) => (dispatch) => {
    let idToken = localStorage.getItem('idToken')
    const orgId = localStorage.getItem("porgId")
    //let uid = localStorage.getItem('uid')
    let date=encodeURIComponent(timestamp)
    dispatch({type:EDITOR_LOADING})
    let url =`/users/${uid}/achievements/${achievementId}/cancel?timestamp=${date}&organization=${orgId}` //users/{userId}/achievements/{achievementId}/cancel
    axiosClient.post(url,null, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        dispatch({ type: GET_CANCEL_ACTIVITY,payload:response.data});
        if(executeActivity) {
            window.location.href = "/success";
        }
        //dispatch({type:REMOVE_TIMER_ACTIVITY,payload:routineId})
    }).catch((error) => {
         // error handling
         console.log("error occured ", error);
         dispatch({ type: STOP_LOADING});  
         dispatch({type: CATCH_API_ERROR, payload: error})   
    })
}
export const networkActivity = (orgIds, showLoading) => (dispatch) => {
    const idToken = localStorage.getItem('idToken')
    const uid = localStorage.getItem('auth_uid')
    let url = `/users/${uid}/network-activity` //`/users/${uid}/network-activity` 
    if (orgIds && orgIds.length > 0) {
        url =`/users/${uid}/network-activity?organizations=${encodeURIComponent(JSON.stringify(orgIds))}` //users/{userId}/network-activity
    } 
    // showLoading != "hideLoading" ? dispatch({type:EDITOR_LOADING}) : ""
    dispatch({type: GET_NETWORK_ACTIVITY})
    axiosClient.get(url,{headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        dispatch({type:GET_NETWORK_ACTIVITY_SUCCESS,payload:response.data})
    }).catch((error) => {
        // error handling
        console.log("error occured ", error);
        dispatch({ type: STOP_LOADING}); 
        dispatch({type: CATCH_API_ERROR, payload: error})
    
    })
}

export const getlaunchpad = (orgIds) => (dispatch) => {
    console.log(" Fetching user launchpad-items for userId....");
    let idToken = localStorage.getItem('idToken')
    let uid = localStorage.getItem('uid')
    dispatch({type:GET_LOADING})
    let url =`/users/${uid}/launchpad-items` //users/{userId}/launchpad-items
    if (orgIds && orgIds.length > 0) {
        url = `/users/${uid}/launchpad-items?organizations=${encodeURIComponent(JSON.stringify(orgIds))}`
    } 

    axiosClient.get(url,{headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        console.log("%c Fetched user lauchpad-items for userId:"+uid,"color:green");
        dispatch({type:GET_LAUNCHPAD,payload:response.data})
    }).catch((error) => {
        // error handling
        const errorMsg = error.response ? error.response.data.message : error.message;
        console.log("%c Error Occured while fetching user launchpad-items ","color:red", errorMsg);
        //console.log("error occured ", error);
        dispatch({ type: STOP_LOADING}); 
        dispatch({type: CATCH_API_ERROR, payload: error})  
    })
}

 
export const getSummeryList=(value)=>(dispatch)=>{
    let organization = localStorage.getItem('orgId')			 
    let scheduled=value.scheduled
    let unscheduled=value.unscheduled
    let uid=localStorage.getItem('uid')
    dispatch({ type: GET_ACTIVITIES_LIST });
    const refValue =  ref(database,`Realtime/${organization}/Users/${uid}/achievementsInProgress`)
    onValue(refValue, (snapshot) => {
        const realtimeData = snapshot.val();
        let inProgressList=[]
        realtimeData&&Object.keys(realtimeData).length > 0?Object.keys(realtimeData).map((dateObjKey, key) => {
            realtimeData[dateObjKey].achievementId=dateObjKey
            inProgressList.push(realtimeData[dateObjKey])
                       
         }):"" 
         scheduled.map((steps,index)=>{
            let add={}
            if(steps.status==="inProgress"||steps.status==="paused")
            {inProgressList.find((li)=>{
               if(li.achievementId===steps.achievementId) {
                add.count=li.stepsCount
                add.state=li.state
                add.scheduleKey = li.scheduleKey
                add.completed=li.StepsCompletion?Object.keys(li.StepsCompletion).length:0 
                steps.steps?Object.keys(steps.steps).map((dateObjKey, key) => {
                    if(key===add.completed-1) add.nextStep=steps.steps[dateObjKey].title                             
                 }):""
                steps={...steps,...add}
                scheduled[index]=steps
            }
               
           })}
        })           
        unscheduled.map((steps,index)=>{
            let add={}
            inProgressList.find((li)=>{
               if(li.routineId===steps.activity.id) {
                add.count=li.stepsCount
                add.status=li.state
                add.completed=li.StepsCompletion?Object.keys(li.StepsCompletion).length:0 
                steps.steps?Object.keys(steps.steps).map((dateObjKey, key) => {
                    if(key===add.completed-1) add.nextStep=steps.steps[dateObjKey].title                             
                 }):""
                 add.achievementId=li.achievementId
                steps={...steps,...add}
                unscheduled[index]=steps
            }
               
           })
        })           
        let data={scheduled:scheduled,unscheduled:unscheduled}
        dispatch({type:GET_SUMMERY_ACTIVITY,payload:data})

})
}

export const adjustAchievementTimer = (id, duration, timestamp) => (dispatch) => {
    let idToken = localStorage.getItem('idToken')
    let uid = localStorage.getItem('uid');
    const orgId = localStorage.getItem("selectedOrgId")
    let date=encodeURIComponent(timestamp)
    let url =`/users/${uid}/achievements/${id}/expected-duration?timestamp=${date}&organization=${orgId}`;
    let payload = {
        "duration": duration
    }
    dispatch({type:ADJUST_ACHIEVEMENT_TIMER})
    axiosClient.post(url,payload, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        console.log(" timer updated for achievement --> ", response.data)
        dispatch({type:ADJUST_ACHIEVEMENT_TIMER_SUCCESS,  payload: response.data})
    }).catch((error) => {
        // error handling
        dispatch({type:ADJUST_ACHIEVEMENT_TIMER_FAILED})
        const errorMsg = error.response ? error.response.data.message : error.message;
        console.log("%c Error Occured while Adjusting achievement timer ","color:red", errorMsg);
    })
}

export const adjustAchievementStepTimer = (id, stepId, duration, timestamp) => (dispatch) => {
    let idToken = localStorage.getItem('idToken')
    let uid = localStorage.getItem('uid');
    const orgId = localStorage.getItem("selectedOrgId")
    let date=encodeURIComponent(timestamp)
    let url =`/users/${uid}/achievements/${id}/steps/${stepId}/expected-duration?timestamp=${date}&organization=${orgId}`;
    let payload = {
        "duration": duration
    }
    dispatch({type:ADJUST_ACHIEVEMENT_TIMER})
    axiosClient.post(url,payload, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        console.log(" timer updated for step --> ", response.data)
        dispatch({type:ADJUST_ACHIEVEMENT_TIMER_SUCCESS, payload: response.data})
    }).catch((error) => {
        // error handling
        dispatch({type:ADJUST_ACHIEVEMENT_TIMER_FAILED})
        const errorMsg = error.response ? error.response.data.message : error.message;
        console.log("%c Error Occured while Adjusting achievement timer ","color:red", errorMsg);
    })
}


export const getCourseFromConfigurator=(courseId)=> (dispatch) => {
    let idToken = localStorage.getItem('idToken')
    let uid = localStorage.getItem('puid')
    let orgId =  localStorage.getItem('porgId');
    dispatch({type:GET_LOADING_FOR_COURSE})
    let url =`/users/${uid}/lms/courses/${courseId}/goto-url?organization=${orgId}`
    axiosClient.post(url,null, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        //console.log("GET_COURES",response.data)
        dispatch({type:GET_COURES,payload:response.data})
    }).catch((error) => {
       // error handling
       console.log("error occured ", error);
       dispatch({ type: STOP_LOADING}); 
       dispatch({type: CATCH_API_ERROR, payload: error})   
    })
}
export const getCourse=(courseId)=> (dispatch) => {
    let idToken = localStorage.getItem('idToken')
    let uid = localStorage.getItem('uid')
    const orgId = localStorage.getItem("selectedOrgId")
    //dispatch({type:REMOVE_COURES})
    console.log(" Fetching course details for courseId:",courseId);
    dispatch({type:GET_LOADING_FOR_COURSE})
    let url =`/users/${uid}/lms/courses/${courseId}/goto-url?organization=${orgId}`
    axiosClient.post(url,null, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        console.log("%c Fetched course details for courseId:"+courseId,"color:green");
        dispatch({type:GET_COURES,payload:response.data})
    }).catch((error) => {
        // error handling
        const errorMsg = error.response ? error.response.data.message : error.message;
        console.log("%c Error occured while fetching course details for courseId:"+courseId,"color:red",errorMsg);
        dispatch({ type: STOP_LOADING}); 
        dispatch({type: CATCH_API_ERROR, payload: error})    
    })
}
export const checkPIN=(value)=> (dispatch) => {
    let idToken = localStorage.getItem('idToken')
    let uid = localStorage.getItem('uid')
    //dispatch({type:REMOVE_COURES})
    let body={pin:value}
    let url =`/users/${uid}/verify-admin-pin` //users/{userId}/verify-admin-pin
    axiosClient.post(url,body, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        //console.log("checkPIN",response.data)
        dispatch({type:GET_PINRESPONSE,payload:response.data.message})
    }).catch((error) => {
       // error handling
       console.log("error occured ", error);
       dispatch({type: CATCH_API_ERROR, payload: error})
       dispatch({ type:GET_PINRESPONSE,payload:'error'});
    })
}
export const removePinMsg=()=>(dispatch)=>{
    dispatch({type:REMOVE_PINRESPONSE})
}
export const getWPQ=()=>(dispatch)=>{
    let idToken = localStorage.getItem('idToken')
    let uid = localStorage.getItem('uid')
    let url =`/users/${uid}/wpq-sign-in-link` //users/{userId}/wpq-sign-in-link
    dispatch({type:GET_WPQ_LOADING})
    axiosClient.post(url,null, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        //console.log("getWPQ",response.data)
        dispatch({type:GET_WPQLINK,payload:response.data})
    }).catch((error) => {
         // error handling
         dispatch({type:HIDE_WPQ_LOADING})
         error = {...error, type:"wpq"}
         dispatch({type: CATCH_API_ERROR, payload: error});
         console.log("error occured ", error);
    })
}

export const setActivityType = (value) => (dispatch) => {
    dispatch({type:SET_ACTIVITY_TYPE,payload:value})
}

export const selectActivityId= (value) => (dispatch) => {
    dispatch({type:SELECT_ACTIVITY_ID,payload:value})
}

export const clearActivityDataOnClose = () => (dispatch) => {
    dispatch({ type: CLEAR_START_ACTIVITY_DETAILS});
}
export const handleShowPhotoCamera = (bool) => (dispatch) => {
    dispatch({ type: HANDLE_SHOW_PHOTO_CAMERA, payload: bool});
}

export const removeAPIError = (data) => (dispatch) => {
    dispatch({type: CATCH_API_ERROR, payload: data})
}

export const hideExecuteActivityConfirmation=()=>(dispatch)=>{
    dispatch({type:HIDE_EXECUTE_ACTIVITY_CONFIRMATION_PANEL})
}
export const showExecuteActivityConfirmation=(value)=>(dispatch)=>{
    dispatch({type:SHOW_EXECUTE_ACTIVITY_CONFIRMATION_PANEL,payload: value})
}

export const handleShowActivityDetailsPanel=(value)=>(dispatch)=>{
    dispatch({type:SHOW_ACTIVITY_DETAILS_PANEL,payload: value})
}

export const handleHideActivityDetailsPanel=()=>(dispatch)=>{
    dispatch({type:HIDE_ACTIVITY_DETAILS_PANEL})
}

export const setRealtimeCommoneActivities = (data) => (dispatch) => {
    dispatch({type: GET_ACTIVITIES_LIST})
    dispatch({ type: UPDATE_ACTIVITEIS_LIST,payload:data });
    dispatch({type:REALTIME_UPDATE_ACTIVITIES, payload: Math.random()});
 }

//Encounter Types Activities
export const getEncounterTypeActivities = (groupId, userId, activityType) => (dispatch) => {
    const idToken = localStorage.getItem('idToken')
    console.log(" Fetching users touchpoints list for userId:"+userId+"......");
    const url = `/users/${userId}/activities?type=${activityType}`
    dispatch({ type: GET_USERS_ECOUNTER_TYPES_ACTIVITIES });
    axiosClient.get(url, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        console.log("activities api response ----.",response.data);
        let activitiesList = response.data;
        if(activitiesList) {
            activitiesList && activitiesList.map(activity => {
                activity.userId = '0I1aJIkeHPOdG4V896xnmvjrStq1';
                activity.fullName = "Client1iprag Test";
            })
        }
        console.log(" Fetched encounter activitties list --> ", activitiesList);
        console.log("%c Fetched user activities list for user:"+userId,"color:green");
        dispatch({ type: GET_USERS_ECOUNTER_TYPES_ACTIVITIES_SUCCESS, payload: activitiesList });
        dispatch(getRealtimeEncounterTypeActivities(groupId, userId, activitiesList))
    }).catch(error => {
        const errorMsg = error.response ? error.response.data.message : error.message;
        console.log("%c Error Occured while fetching user activities list for user:"+userId,"color:red", errorMsg);
    })
}

export const getRealtimeEncounterTypeActivities = (groupId, userId, data) => (dispatch) =>{
    let organization = localStorage.getItem('organization')
    const auth_uid = localStorage.getItem("auth_uid")
    dispatch({ type: MAP_USERS_ECOUNTER_TYPES_ACTIVITIES_REALTIME_DATA });
    const refValue = ref(database, `Realtime/${organization}/Groups/${groupId}/${userId}/achievementsInProgress`)
    const topRef = query(refValue, orderByChild('preview').equalTo(false))
    onValue(topRef, (snapshot) => {
        const realtimeData = snapshot.val();
        console.log("Fetched Real time of encounter activities ", realtimeData);
        let inProgressList=[];
        realtimeData&&Object.keys(realtimeData).length > 0?Object.keys(realtimeData).map((dateObjKey, key) => {
            realtimeData[dateObjKey].achievementId=dateObjKey
            inProgressList.push(realtimeData[dateObjKey])
                       
        }):"" ;
        data.map((step,index)=>{
            data[index].state=''
        })
         data.map((steps,index)=>{
            let add={}
            inProgressList.map((li)=>{
               if(li.routineId===steps.id) {
                add.count=li.stepsCount
                add.state=li.state;
                add.startDate = li.startDate;
                add.routineUserId = li.routineUserId;
                add.showResumeButton = li.routineUserId === auth_uid ? true : false
                if(li.routine) {
                    if(li.routine.steps && Object.keys(li.routine.steps).length > 0) {
                        add.steps = li.routine.steps;
                    }
                    if(li.routine.expectedDuration) {
                        add.expectedDuration = li.routine.expectedDuration
                    }
                }
                let stepsCompletion = li.StepsCompletion ? li.StepsCompletion : {};
                let stepsCompletionList = [];
                if (stepsCompletion) {
                  Object.keys(stepsCompletion).map((stepCompId, key) => {
                       stepsCompletionList.push(stepsCompletion[stepCompId]);
                  });
                  stepsCompletionList = stepsCompletionList.filter(step => step.status == "Done" || step.status == "Skipped" )
                }
                add.stepsCompletionList = stepsCompletionList;
                add.completed = stepsCompletionList.length;
                if(add.completed > 0 && stepsCompletionList[add.completed - 1].step) {
                    add.nextStep = stepsCompletionList[add.completed - 1].step.title;
                }
                add.scheduleKey = li.scheduleKey
                add.achievementId=li.achievementId;
                if(li.routine && li.routine.activityGroupId) {
                    add.activityGroupId = li.routine.activityGroupId
                }

                steps={...steps,...add}
                data[index]=steps
            }         
           })
        })  
        console.log("%c Updated user activities list with realtime data for user:"+userId,"color:green"); 
       dispatch({ type: MAP_USERS_ECOUNTER_TYPES_ACTIVITIES_REALTIME_DATA });   
       dispatch({type: MAP_USERS_ECOUNTER_TYPES_ACTIVITIES_REALTIME_DATA_SUCCESS, payload: data});    
    })
 }

 export const handleShowEncounterActivityHistory = (bool) => (dispatch) => {
    dispatch({type: HANDLE_ENCOUNTER_ACTIVITY_HISTORY, payload: bool})
}
export const handleShowEncounterActivityDetails = (bool) => (dispatch) => {
    dispatch({type: HANDLE_ENCOUNTER_ACTIVITY_DETAILS, payload: bool})
}
export const handleExecuteEncounterActivity = (bool) => (dispatch) => {
    dispatch({type: HANDLE_ENCOUNTER_ACTIVITY_EXECUTION, payload: bool})
}

export const setClientActivitiesType = (type) => (dispatch) => {
    dispatch({type: SET_CLIENT_ACTIVITIES_TYPE, payload: type})
}

export const gettouchpointActivitiesSummary = (userId, orgIds) => (dispatch) => {
    const idToken = localStorage.getItem('idToken')
    let url = `/users/${userId}/activities/touchpoint-summary`
    if (orgIds && orgIds.length > 0) {
        url = `/users/${userId}/activities/touchpoint-summary?organizations=${encodeURIComponent(JSON.stringify(orgIds))}`
    }
    dispatch({ type: GET_ECOUNTER_ACTIVITIES_SUMMARY });
    axiosClient.get(url, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        console.log(" Fetched encounter activitties summary --> ", response.data);
        dispatch({ type: GET_ECOUNTER_ACTIVITIES_SUMMARY_SUCCESS, payload: response.data });
        dispatch(mappedEncounterSummaryWithRealtime(userId, response.data))
    }).catch(error => {
        dispatch({type: GET_ECOUNTER_ACTIVITIES_SUMMARY_FAILED})
        const errorMsg = error.response ? error.response.data.message : error.message;
        console.log("%c Error Occured while fetching encounter activities list for user:"+userId,"color:red", errorMsg);
    })
}

export const mappedEncounterSummaryWithRealtime = (userId, apiData) => (dispatch) =>{
    let organization = localStorage.getItem('selectedOrgId');
    const auth_uid = localStorage.getItem("auth_uid")
    const refValue = ref(database, `Realtime/${organization}/Users/${userId}/touchpointsInProgress`)
    onValue(refValue, (snapshot) => {
        const realtimeData = snapshot.val();
        console.log("Realtime encounter activities data -->", realtimeData)
        let realtimeEncounterList=[];
        realtimeData && Object.keys(realtimeData).length > 0 && Object.keys(realtimeData).map((dateObjKey, key) => {
            let encoounterUser = {userId: dateObjKey}
            let inProgressList = [];
            let encountersInProgress = realtimeData[dateObjKey];
            encountersInProgress && Object.keys(encountersInProgress).length > 0 && Object.keys(encountersInProgress).map((ObjKey, key) => {
                let achievement = encountersInProgress[ObjKey];
                let updatedRoutine = {...achievement.routine};
                updatedRoutine.achievementId = ObjKey;
                updatedRoutine.id = achievement.routineId;
                updatedRoutine.count = achievement.stepsCount;
                updatedRoutine.state = achievement.state
                updatedRoutine.startDate = achievement.startDate
                updatedRoutine.routineUserId = achievement.routineUserId
                updatedRoutine.showResumeButton = achievement.routineUserId === auth_uid ? true : false
                let stepsCompletion = achievement.StepsCompletion ? achievement.StepsCompletion : {};
                let stepsCompletionList = [];
                if (stepsCompletion) {
                    Object.keys(stepsCompletion).map((stepCompId, key) => {
                        stepsCompletionList.push(stepsCompletion[stepCompId]);
                    });
                    stepsCompletionList = stepsCompletionList.filter(step => step.status == "Done" || step.status == "Skipped" )
                }
                updatedRoutine.stepsCompletionList = stepsCompletionList;
                updatedRoutine.completed = stepsCompletionList.length;
                if(updatedRoutine.completed > 0 && stepsCompletionList[updatedRoutine.completed - 1].step) {
                    updatedRoutine.nextStep = stepsCompletionList[updatedRoutine.completed - 1].step.title;
                }
                const {timestamps = {}, startDate, createdAt} = achievement;
                const createdDate = createdAt || timestamps["notStarted,notStarted"] || timestamps["notStarted,delayed"] || startDate;
                const scheduleNotExpired = isAchievementCreatedByToday(createdDate)
                updatedRoutine.scheduleNotExpired = scheduleNotExpired
                if(achievement.preview === false) {
                    inProgressList.push(updatedRoutine)
                }  
            }) 
            encoounterUser.touchpointsInProgressCount = inProgressList.length;
            realtimeEncounterList.push(encoounterUser);
         })
        // Mapping realtime data

        apiData.map(encounter => {
            let realtimeEncounter = realtimeEncounterList.find(ele => ele.userId == encounter.userId) || null;
            // if(realtimeEncounter) {
            //     encounter.touchpointsInProgressCount = realtimeEncounter.touchpointsInProgressCount;
            // } 
        })
        console.log(" updated encounter inprogress list --> ", apiData);
        dispatch({ type: GET_ECOUNTER_ACTIVITIES_SUMMARY });
        dispatch({ type: GET_ECOUNTER_ACTIVITIES_SUMMARY_SUCCESS, payload: apiData });  
    }); 
 }

export const resetWidgetData = () => (dispatch) => {
    dispatch({type: RESET_ACTIVITIES_CHAT_CONTACT_GROUP_LIST})
}

export const assingCommonActivityToClient = (filter, groupId, activityType, activityId) => (dispatch) => {

    const userId = localStorage.getItem("uid")
    const orgId = localStorage.getItem("selectedOrgId")
    const idToken = localStorage.getItem("idToken")

    console.log(" Unassigning unchecked activities for userId:",userId,"......");
    dispatch({ type: UNASSIGN_CLIENT_COMMON_ACTIVITY });
    axiosClient.post(`/users/${userId}/activities/un-assign?orgId=${orgId}&scope=General`, { activityIds: activityId }, {
        headers: {
            'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        if (response.status === 200) {   
            console.log("%c Unassigned unchecked activities for userId:"+userId,"color:green"); 
            dispatch({ type: UNASSIGN_CLIENT_COMMON_ACTIVITY_SUCCESS });
            dispatch(getActivitiesListN({orgId, userId, filter, groupId, activityType}))
        } else {
            dispatch({ type: HIDE_UNASSIGN_ACTIVITY_LOADING });
        }
            
    }).catch(error => {
        const errorData = error.response ? { message: (error.response.data && error.response.data.messsage), title: 'Warning' } : { message: "Network connection is not available. Changes cannot be saved at this time", title: "Network Disconnected" };
        // const errorData = { message: "Network connection is not available. Changes cannot be saved at this time", title: "Network Disconnected" }
        dispatch({ type: UNASSIGN_ACTIVITY_TO_CLIENT_FAILED, payload: errorData })
        const errorMsg = error.response && error.response.data ? error.response.data.message : error.message;
        console.log("%c Error occured while unassigning unchecked activity for userId:"+userId,"color:red",errorMsg);
    })
}

export const setActivityGroup = (activityGroups) => (dispatch) => {
    dispatch({type: SELECT_ACTIVITY_FILTER_GROUP, payload: activityGroups})
}

export const showExecuteActivityPannel = (data) => (dispatch) => {
    dispatch({type: SHOW_EXECUTE_ACTIVITY_PANEL, payload: data})
}